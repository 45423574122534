import { BiLogInCircle } from "react-icons/bi";
import { Button, Input } from "components/Form";
import useDisclosure from "hooks/useDisclosure";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch } from "hooks/redux";
import { useNavigate } from "react-router-dom";
import { executeLogin } from "apis/auth";
import toast from "react-hot-toast";
import { populateAccount } from "features/accountSlice";

function AdminLogin() {
  const { register, handleSubmit, getValues, trigger } = useForm<LoginData>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleLogin: SubmitHandler<LoginData> = async (data) => {
    try {
      if (!(await trigger())) return;
      onOpen();
      const payload: LoginData = {
        email: getValues("email").trim(),
        password: getValues("password").trim(),
      };
      const result = await executeLogin(payload);
      if (result.isSuccess === false) throw new Error(result.message);

      toast.success(result.message);
      dispatch(
        populateAccount({
          user: result.data.user,
          token: result.data.token,
          dashboardModules: result.data.dashboardModules,
          role: result.data.role,
        })
      );
      navigate("/");
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      onClose();
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-my">
      <div className="flex flex-1 justify-center items-center p-6 lg:p-0 mt-8">
        <div className="flex w-full lg:w-3/4 max-w-5xl items-center space-x-52">
          <div className="w-1/2 flex justify-center lg:justify-end p-6 lg:p-0">
            <img
              src="/images/kano.png"
              alt="logo"
              className="w-3/4 h-auto object-contain"
            />
          </div>

          <div className="w-1/2 bg-white rounded-md shadow-lg p-8">
            <h1 className="text-lg font-semibold text-gray-800 mb-4">Login</h1>
            <form onSubmit={handleSubmit(handleLogin)}>
              <Input
                label="Login ID"
                type="text"
                color={true}
                className="mb-4"
                name={"email"}
                register={register}
              />
              <Input
                label="Password"
                type="password"
                color={true}
                className="mb-4"
                name={"password"}
                register={register}
              />

              <div className="flex justify-between items-center mt-6">
                <Button
                  label="Login Now"
                  Icon={BiLogInCircle}
                  className="bg-red-500 hover:bg-red-600"
                  loading={isOpen}
                />
                <button
                  type="button"
                  className="text-sm underline text-gray-500 hover:text-gray-700"
                  onClick={() => {
                    /* Handle forgot password */
                  }}
                >
                  Forgot Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <footer className="text-center text-sm text-white p-4 mt-auto">
        <p>Copyright © 2024 Kano State Revenue Agency. All rights reserved.</p>
        <div className="flex justify-center items-center mt-2">
          <span className="mr-1">Powered by</span>
          <img
            src="/images/logo.png"
            alt="logo"
            className="w-14 h-8 object-contain"
          />
        </div>
      </footer>
    </div>
  );
}

export default AdminLogin;
