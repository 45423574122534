import { useState } from "react";
import Layout from "Layout";
import PaymentTableHeader from "components/TableHeaders/PaymentTableHeader";
import CustomTable from "components/Table/CustomTable";
import { columns } from "./helpers";
import AddPaymentItemModal from "components/Modals/AddPaymentItemModal";
import { useAppSelector } from "hooks/redux";
import {
  executeCreatePaymentItem,
  executeGetDepartments,
  executeGetFaculties,
  executeGetFees,
  executeGetPaymentCategory,
} from "apis/students";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import useDisclosure from "hooks/useDisclosure";

function PaymentItemsTable() {
  const [showPaymentItemModal, setShowPaymentItemModal] = useState({
    show: false,
    paymentItem: {},
  });
  const school = useAppSelector((state) => state.school.selectedSchool!);
  const token = useAppSelector((state) => state.account.token.value);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: fees } = useQuery({
    queryKey: ["fees", school.id, token],
    queryFn: () =>
      executeGetFees({
        queryKey: ["faculties", school.id],
        token,
      }),
  });

  const { data: faculties } = useQuery({
    queryKey: ["faculties", school.id, token],
    queryFn: () =>
      executeGetFaculties({
        queryKey: ["faculties", school.id],
        token,
      }),
  });

  const { data: departments } = useQuery({
    queryKey: ["departments", school.id, token],
    queryFn: () =>
      executeGetDepartments({
        queryKey: ["departments", school.id],
        token,
      }),
  });

  const { data: categories } = useQuery({
    queryKey: ["categories", school.id, token],
    queryFn: () =>
      executeGetPaymentCategory({
        queryKey: ["departments", school.id],
        token,
      }),
  });

  const closePaymentItemModal = () =>
    setShowPaymentItemModal({ show: false, paymentItem: {} });

  const openNewPaymentItemModal = () => {
    setShowPaymentItemModal({ show: true, paymentItem: {} });
  };

  const handleSubmit = async (data: any) => {
    try {
      onOpen();
      const selectedFacultiesIds = data.faculties.map((f: any) => f.value);
      const selectedDepartmentsIds = data.departments.map((d: any) => d.value);
      const selectedLevelsIds = data.levels.map((l: any) => l.value);
      const selectedFee = categories?.find(
        (fee: any) => fee.id === parseInt(data.paymentType)
      );

      if (!selectedFee) {
        throw new Error("Fee not found");
      }
      const payload = {
        faculties: selectedFacultiesIds,
        departments: selectedDepartmentsIds,
        levels: selectedLevelsIds, // IDs for levels
        school_id: school.id,
        payment_category: selectedFee.id,
        name: selectedFee.name,
        description: selectedFee.name,
        amount: data.amount,
        due_date: data.dueDate,
      };
      const result = await executeCreatePaymentItem(payload);
      if (result.isSuccess === false) throw new Error(result.message);
      toast.success(result.message);
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      onClose();
      closePaymentItemModal();
    }
  };

  return (
    <Layout>
      <PaymentTableHeader openNewItemModal={openNewPaymentItemModal} />

      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="10"
        data-aos-offset="200"
        className="my-8 rounded-xl border-[1px] border-border"
      >
        <div className="w-full overflow-x-scroll bg-white p-5">
          <CustomTable data={fees || []} columns={columns} />
        </div>
      </div>
      {showPaymentItemModal && faculties && departments && (
        <AddPaymentItemModal
          isOpen={showPaymentItemModal.show}
          closeModal={closePaymentItemModal}
          paymentItem={showPaymentItemModal.paymentItem}
          categories={categories ?? []}
          departments={departments ?? []}
          faculties={faculties ?? []}
          onSubmit={handleSubmit}
          isLoading={isOpen}
        />
      )}
    </Layout>
  );
}

export default PaymentItemsTable;
