import toast from "react-hot-toast";
import { FiEye } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { formatReadableDate } from "utils/index";

export const columns = [
  {
    title: "ID No.",
    accessor: "id",
    render: (item: any) => <h4 className="text-sm font-medium">{item.id}</h4>,
  },
  {
    title: "Name",
    accessor: "name",
  },
  {
    title: "Email",
    accessor: "email",
  },
  { title: "Department", accessor: "department" },
  { title: "Date", accessor: "date" },
  {
    title: "Amount",
    accessor: "amount",
    render: (item: any) => (
      <span className="text-xs font-medium">{item.amount}</span>
    ),
  },
  {
    title: "Description",
    accessor: "description",
    render: (item: any) => <span className="text-xs">{item.description}</span>,
  },
  { title: "Transaction ID", accessor: "descriptionId" },
].filter(Boolean);

export const paymentItemColumnsTwo = [
  {
    title: "Name",
    accessor: "name",
  },
  {
    title: "PAY Ref.",
    accessor: "id",
    render: (item: any) => <h4 className="text-sm font-medium">{item.id}</h4>,
  },
  {
    title: "Faculty",
    accessor: "faculty",
  },
  { title: "Department", accessor: "department" },
  {
    title: "Level",
    accessor: "level",
    render: (item: any) => <span className="text-xs">{item.level}</span>,
  },
  {
    title: "Amount",
    accessor: "amount",
    render: (item: any) => (
      <span className="text-xs font-medium">{item.amount}</span>
    ),
  },
  { title: "Date", accessor: "date" },
  { title: "Transaction ID", accessor: "descriptionId" },
].filter(Boolean);

export const paymentItemColumns = [
  {
    title: "Name",
    accessor: "invoice",
    render: (item: any) => (
      <h4 className="text-sm font-medium">
        {item.invoice.student.user.firstName}{" "}
        {item.invoice.student.user.lastName}
      </h4>
    ),
  },
  {
    title: "PAY Ref.",
    accessor: "id",
    render: (item: any) => (
      <h4 className="text-sm font-medium">{item.invoice.invoiceNumber}</h4>
    ),
  },
  {
    title: "Department",
    accessor: "department",
    render: (item: any) => (
      <h4 className="text-sm font-medium">
        {item.invoice.fee.department.name}
      </h4>
    ),
  },
  {
    title: "Level",
    accessor: "level",
    render: (item: any) => (
      <h4 className="text-sm font-medium">{item.invoice.fee.level}</h4>
    ),
  },
  {
    title: "Amount",
    accessor: "amount",
    render: (item: any) => (
      <span className="text-xs font-medium">{item.amount}</span>
    ),
  },
  {
    title: "Date",
    accessor: "date",
    render: (item: any) => (
      <span className="text-xs font-medium">
        {formatReadableDate(item.createdAt)}
      </span>
    ),
  },
  {
    title: "Transaction ID",
    accessor: "descriptionId",
    render: (item: any) => (
      <span className="text-xs font-medium">{item.paymentLogId}</span>
    ),
  },
  {
    title: "Status",
    accessor: "status",
    render: (item: any) => (
      <span
        className={`text-xs font-medium px-2.5 py-0.5 rounded-full ${
          item.status === "COMPLETED"
            ? "bg-green-200 text-green-800"
            : "bg-red-200 text-red-800"
        }`}
      >
        {item.status === "COMPLETED" ? "PAID" : "UNPAID"}
      </span>
    ),
  },
].filter(Boolean);

export const actions = (previewPayment: (id: number) => void) => [
  {
    title: "View",
    icon: FiEye,
    onClick: (item: any) => {
      previewPayment(item.id);
    },
  },
  {
    title: "Delete",
    icon: RiDeleteBin6Line,
    onClick: () => toast.error("This feature is not available yet"),
  },
];

export const paymentData = [
  {
    id: "ERG4883933",
    name: "Ifeanyi Ilodibe",
    email: "ifeanyi.ilodibe@example.com",
    description: "Tuition Fee",
    department: "Computer Science",
    date: "2024-08-15",
    amount: "₦150,000",
    descriptionId: "TUI2024CS001",
    level: "400",
    faculty: "Faculty of Science",
  },
  {
    id: "ERG4883934",
    name: "Ngozi Okonkwo",
    email: "ngozi.okonkwo@example.com",
    description: "Accommodation Fee",
    department: "Biochemistry",
    date: "2024-08-10",
    amount: "₦50,000",
    descriptionId: "ACC2024BC002",
    level: "300",
    faculty: "Faculty of Biological Sciences",
  },
  {
    id: "ERG4883935",
    name: "Chinedu Eze",
    email: "chinedu.eze@example.com",
    description: "Lab Fee",
    department: "Physics",
    date: "2024-08-12",
    amount: "₦20,000",
    descriptionId: "LAB2024PH003",
    level: "200",
    faculty: "Faculty of Science",
  },
  {
    id: "ERG4883936",
    name: "Amaka Nwosu",
    email: "amaka.nwosu@example.com",
    description: "Sports Fee",
    department: "Human Kinetics",
    date: "2024-08-11",
    amount: "₦10,000",
    descriptionId: "SPT2024HK004",
    level: "100",
    faculty: "Faculty of Education",
  },
  {
    id: "ERG4883937",
    name: "Emeka Okeke",
    email: "emeka.okeke@example.com",
    description: "Library Fee",
    department: "Library Science",
    date: "2024-08-13",
    amount: "₦5,000",
    descriptionId: "LIB2024LS005",
    level: "400",
    faculty: "Faculty of Arts",
  },
  {
    id: "ERG4883938",
    name: "Adaobi Obi",
    email: "adaobi.obi@example.com",
    description: "Examination Fee",
    department: "Mass Communication",
    date: "2024-08-14",
    amount: "₦30,000",
    descriptionId: "EXM2024MC006",
    level: "300",
    faculty: "Faculty of Social Sciences",
  },
  {
    id: "ERG4883939",
    name: "Uchechi Nwankwo",
    email: "uchechi.nwankwo@example.com",
    description: "Graduation Fee",
    department: "Law",
    date: "2024-08-16",
    amount: "₦70,000",
    descriptionId: "GRD2024LW007",
    level: "500",
    faculty: "Faculty of Law",
  },
  {
    id: "ERG4883940",
    name: "Nkemdilim Uzor",
    email: "nkemdilim.uzor@example.com",
    description: "Development Levy",
    department: "Engineering",
    date: "2024-08-17",
    amount: "₦25,000",
    descriptionId: "DEV2024EN008",
    level: "400",
    faculty: "Faculty of Engineering",
  },
  {
    id: "ERG4883941",
    name: "Oluchi Madu",
    email: "oluchi.madu@example.com",
    description: "Transport Fee",
    department: "Business Administration",
    date: "2024-08-18",
    amount: "₦15,000",
    descriptionId: "TRN2024BA009",
    level: "200",
    faculty: "Faculty of Management Sciences",
  },
  {
    id: "ERG4883942",
    name: "Chisom Anya",
    email: "chisom.anya@example.com",
    description: "Medical Fee",
    department: "Medicine",
    date: "2024-08-19",
    amount: "₦40,000",
    descriptionId: "MED2024MD010",
    level: "500",
    faculty: "Faculty of Medicine",
  },
];

export default paymentData;
