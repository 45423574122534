import { createSlice } from "@reduxjs/toolkit";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";

type InitialStateType = {
  selectedSchool: School | null;
  schools: School[];
};
const initialState: InitialStateType = {
  selectedSchool: null,
  schools: [],
};

const schoolSlice = createSlice({
  name: "school",
  initialState,
  reducers: {
    populateSchools: (state, action) => {
      state.schools = action.payload;
    },
    populateSelectedSchool: (state, action) => {
      state.selectedSchool = action.payload;
    },
  },
});

const schoolPersistConfig = {
  key: "school",
  storage: storage,
};

export const { populateSchools, populateSelectedSchool } =
  schoolSlice.actions;

const schoolReducer = persistReducer(schoolPersistConfig, schoolSlice.reducer);

export default schoolReducer;
