import axios from "axios";
import {
  GET_STUDENTS_ENDPOINT,
  GET_PATIENTS_IN_HOSPITAL_ENDPOINT,
  GET_PATIENT_ENDPOINT,
  GET_PATIENT_INVOICES_ENDPOINT,
  REGISTER_PATIENT_ENDPOINT,
  UPDATE_PATIENT_STATUS_ENDPOINT,
  REGISTER_MULTIPLE_USERS_ENDPOINT,
  CREATE_PAYMENT_ITEMS,
  GET_ALL_INVOICES,
  GET_DEPARTMENTS_ENDPOINT,
  GET_FACULTIES_ENDPOINT,
  GET_PAYMENTS_CATEGORY_ENDPOINT,
  GET_FEES,
  GET_PAYMENTS_STATUS_ENDPOINT,
  UPDATE_PAYMENT_STATUS,
} from ".";
import { useMutation } from "@tanstack/react-query";

export const executeGetStudentsBySchool = async ({
  queryKey,
}: {
  queryKey: [string, number];
}) => {
  const [, id] = queryKey;
  const response = await axios.get(`${GET_STUDENTS_ENDPOINT(id)}`);
  return response.data.data.data;
};

export const executeGetFaculties = async ({
  queryKey,
  token,
}: {
  queryKey: [string, number];
  token: string;
}) => {
  console.log({ queryKey });
  const response = await axios.get(`${GET_FACULTIES_ENDPOINT()}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.data.data;
};

export const executeGetFees = async ({
  queryKey,
  token,
}: {
  queryKey: [string, number];
  token: string;
}) => {
  const [, id] = queryKey;
  console.log({ id });
  const response = await axios.get(`${GET_FEES()}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.data.data;
};

export const executeGetDepartments = async ({
  queryKey,
  token,
}: {
  queryKey: [string, number];
  token: string;
}) => {
  const response = await axios.get(`${GET_DEPARTMENTS_ENDPOINT()}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.data.data;
};

export const executeGetPaymentCategory = async ({
  token,
}: {
  queryKey: [string, number];
  token: string;
}) => {
  const response = await axios.get(`${GET_PAYMENTS_CATEGORY_ENDPOINT()}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.data.data;
};

export const executeGetPaymentStatus = async (
  merchantCode: string,
  transactionReference: string,
  amount: number
) => {
  const { data } = await axios.get(GET_PAYMENTS_STATUS_ENDPOINT, {
    params: { merchantCode, amount, transactionReference }, // Sending query parameters
  });
  return data; // Return the data from the response
};

export const useSubmitPaymentDetails = () => {
  return useMutation({
    mutationFn: async (paymentDetails: any) => {
      const response = await axios.post(UPDATE_PAYMENT_STATUS, paymentDetails);
      return response.data;
    },
  });
};

export const executeGetPatientById = async (
  id: string
): Promise<ResponseDataType> => {
  try {
    const options = {
      url: GET_PATIENT_ENDPOINT(id),
    };
    const response = (await axios(options)) satisfies ResponseDataType;
    return {
      message: response.data.message,
      status: "error",
      data: response.data.data,
      isSuccess: true,
    };
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: error };
  }
};

export const executeGetPatientInvoices = async (
  id: string
): Promise<ResponseDataType> => {
  try {
    const options = {
      url: GET_PATIENT_INVOICES_ENDPOINT(id),
    };
    const response = (await axios(options)) satisfies ResponseDataType;
    return {
      message: response.data.message,
      status: "error",
      data: response.data.data,
      isSuccess: true,
    };
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: error };
  }
};

export const executeUpdatePatientStatus = async (
  payload: any,
  token: string
): Promise<ResponseDataType> => {
  try {
    const options = {
      url: UPDATE_PATIENT_STATUS_ENDPOINT,
      data: payload,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = (await axios(options)) satisfies ResponseDataType;
    return {
      message: response.data.message,
      status: "error",
      data: response.data.data,
      isSuccess: true,
    };
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: error };
  }
};

export const executeGetPatientsInHospital = async (
  hospitalId: number,
  token: string
): Promise<ResponseDataType> => {
  try {
    const options = {
      url: GET_PATIENTS_IN_HOSPITAL_ENDPOINT(hospitalId),
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = (await axios(options)) satisfies ResponseDataType;
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: error };
  }
};

export const executeRegisterPatient = async (
  data: FormData,
  token: string
): Promise<ResponseDataType> => {
  try {
    const options = {
      url: REGISTER_PATIENT_ENDPOINT,
      data: data,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = (await axios(options)) satisfies ResponseDataType;
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      return error.response.data;
    } else {
      return { message: error.message, status: "error" } as ResponseDataType;
    }
  }
};

export const executeRegisterMultipleStudent = async (
  data: any
): Promise<ResponseDataType> => {
  try {
    const options = {
      method: "POST",
      url: REGISTER_MULTIPLE_USERS_ENDPOINT,
      data: data,
    };

    const response = (await axios(options)) satisfies ResponseDataType;
    return response.data;
  } catch (error: any) {
    return {
      message: error.response.data.message,
      isSuccess: false,
      status: error,
    };
  }
};

export const executeCreatePaymentItem = async (
  data: any
): Promise<ResponseDataType> => {
  try {
    const options = {
      method: "POST",
      url: CREATE_PAYMENT_ITEMS,
      data: data,
    };

    const response = (await axios(options)) satisfies ResponseDataType;
    return response.data;
  } catch (error: any) {
    return {
      message: error.response.data.message,
      isSuccess: false,
      status: error,
    };
  }
};

export const executeGetInvoices = async (
  id: number
): Promise<ResponseDataType> => {
  try {
    const options = {
      url: GET_ALL_INVOICES(id),
    };
    const response = (await axios(options)) satisfies ResponseDataType;
    console.log({ response });
    return response.data.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: error };
  }
};
