interface IconProps {
  color?: string;
  size?: number;
}
export const BankIcon: React.FC<IconProps> = ({
  color = "white",
  size = 29,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6_766)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2469 8.91021C27.7674 8.91021 29 7.67759 29 6.15707C29 4.93461 28.1939 3.85855 27.0207 3.51493L15.5189 0.146148C14.8536 -0.048716 14.1464 -0.048716 13.4811 0.146148L1.97928 3.51493C0.806096 3.85855 0 4.93461 0 6.15707C0 7.67759 1.23262 8.91021 2.75314 8.91021H26.2469ZM2.72021 6.14643C2.72021 6.14643 2.71875 6.15141 2.71875 6.15707C2.71875 6.16302 2.71949 6.16688 2.71949 6.16688L2.72034 6.16963C2.72034 6.16963 2.72374 6.1763 2.72882 6.18139C2.73391 6.18648 2.74059 6.18988 2.74059 6.18988L2.74333 6.19072C2.74333 6.19072 2.74719 6.19146 2.75314 6.19146H26.2469C26.2528 6.19146 26.2567 6.19072 26.2567 6.19072L26.2594 6.18988C26.2594 6.18988 26.2661 6.18648 26.2712 6.18139C26.2763 6.1763 26.2797 6.16962 26.2797 6.16962L26.2805 6.16688C26.2805 6.16688 26.2812 6.16302 26.2812 6.15707C26.2812 6.15141 26.2798 6.14643 26.2798 6.14643C26.2798 6.14643 26.2772 6.14017 26.2744 6.13646C26.2716 6.13274 26.2663 6.12847 26.2663 6.12847C26.2663 6.12847 26.262 6.12566 26.2565 6.12407L14.7547 2.75529C14.5884 2.70657 14.4116 2.70657 14.2453 2.75529L2.74347 6.12407C2.73803 6.12566 2.73367 6.12847 2.73367 6.12847C2.73367 6.12847 2.7284 6.13274 2.72562 6.13646C2.72283 6.14017 2.72021 6.14643 2.72021 6.14643Z"
          fill={color}
        />
        <path
          d="M24.0156 18.879V11.629C24.0156 10.8782 23.407 10.2696 22.6562 10.2696C21.9055 10.2696 21.2969 10.8782 21.2969 11.629V18.879C21.2969 19.6297 21.9055 20.2383 22.6562 20.2383C23.407 20.2383 24.0156 19.6297 24.0156 18.879Z"
          fill={color}
        />
        <path
          d="M13.1406 18.879C13.1406 19.6297 13.7492 20.2383 14.5 20.2383C15.2508 20.2383 15.8594 19.6297 15.8594 18.879V11.629C15.8594 10.8782 15.2508 10.2696 14.5 10.2696C13.7492 10.2696 13.1406 10.8782 13.1406 11.629V18.879Z"
          fill={color}
        />
        <path
          d="M7.70312 11.629C7.70312 10.8782 7.09451 10.2696 6.34375 10.2696C5.59299 10.2696 4.98438 10.8782 4.98438 11.629V18.879C4.98438 19.6297 5.59299 20.2383 6.34375 20.2383C7.09451 20.2383 7.70312 19.6297 7.70312 18.879V11.629Z"
          fill={color}
        />
        <path
          d="M0 27.6351C0 26.8843 0.608613 26.2757 1.35938 26.2757H27.6406C28.3914 26.2757 29 26.8843 29 27.6351C29 28.3859 28.3914 28.9945 27.6406 28.9945H1.35938C0.608613 28.9945 0 28.3859 0 27.6351Z"
          fill={color}
        />
        <path
          d="M4.53125 21.75C3.78049 21.75 3.17188 22.3586 3.17188 23.1094C3.17188 23.8601 3.78049 24.4688 4.53125 24.4688H24.4688C25.2195 24.4688 25.8281 23.8601 25.8281 23.1094C25.8281 22.3586 25.2195 21.75 24.4688 21.75H4.53125Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_6_766">
          <rect width="29" height="29" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const DashboardIcon: React.FC<IconProps> = ({
  color = "white",
  size = 29,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6_593)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.5 0C6.49187 0 0 6.49187 0 14.5C0 22.5081 6.49187 29 14.5 29C22.5081 29 29 22.5081 29 14.5C29 6.49187 22.5081 0 14.5 0ZM2.81305 14.5C2.81305 8.04548 8.04548 2.81305 14.5 2.81305C20.9545 2.81305 26.1869 8.04548 26.1869 14.5C26.1869 20.9545 20.9545 26.1869 14.5 26.1869C8.04548 26.1869 2.81305 20.9545 2.81305 14.5Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.37304 9.37348C9.9039 8.84261 10.7646 8.84261 11.2955 9.37348L14.4996 12.5776L17.7036 9.37348C18.2345 8.84261 19.0952 8.84261 19.6261 9.37348C20.157 9.90435 20.157 10.7651 19.6261 11.2959L16.422 14.5L19.6261 17.7041C20.157 18.235 20.157 19.0957 19.6261 19.6265C19.0952 20.1574 18.2345 20.1574 17.7036 19.6265L14.4996 16.4224L11.2955 19.6265C10.7646 20.1574 9.9039 20.1574 9.37304 19.6265C8.84217 19.0957 8.84217 18.235 9.37304 17.7041L12.5771 14.5L9.37304 11.2959C8.84217 10.7651 8.84217 9.90435 9.37304 9.37348Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_6_593">
          <rect width="29" height="29" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const GlobeIcon: React.FC<IconProps> = ({
  color = "white",
  size = 29,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6_763)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 14.5C0 6.49187 6.49187 0 14.5 0C22.5081 0 29 6.49187 29 14.5C29 22.5081 22.5081 29 14.5 29C6.49187 29 0 22.5081 0 14.5ZM8.6271 13.1406H2.89126C3.41035 8.6595 6.46419 4.95098 10.5818 3.48605C9.55857 5.36796 8.75479 8.35968 8.6271 13.1406ZM14.5 3.18959C14.5347 3.2008 14.5797 3.21762 14.6332 3.24258C14.8372 3.33782 15.2236 3.57832 15.6595 4.18855C16.4854 5.34486 17.5033 7.85543 17.6531 13.1406H11.3469C11.4967 7.8554 12.5146 5.34483 13.3405 4.18853C13.7764 3.57831 14.1628 3.33781 14.3668 3.24258C14.4203 3.21762 14.4653 3.2008 14.5 3.18959ZM8.6271 15.8594C8.75479 20.6403 9.55859 23.6321 10.5818 25.514C6.4642 24.049 3.41035 20.3405 2.89126 15.8594H8.6271ZM14.5 25.8104C14.4653 25.7992 14.4203 25.7824 14.3668 25.7574C14.1628 25.6622 13.7764 25.4217 13.3405 24.8114C12.5146 23.6551 11.4967 21.1446 11.3469 15.8594H17.6531C17.5033 21.1446 16.4854 23.6552 15.6595 24.8115C15.2236 25.4217 14.8372 25.6622 14.6332 25.7574C14.5797 25.7824 14.5347 25.7992 14.5 25.8104ZM20.3729 15.8594C20.2452 20.6403 19.4414 23.632 18.4182 25.5139C22.5358 24.049 25.5897 20.3405 26.1087 15.8594H20.3729ZM18.4182 3.48605C19.4414 5.36795 20.2452 8.35968 20.3729 13.1406H26.1087C25.5897 8.65949 22.5358 4.95097 18.4182 3.48605Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_6_763">
          <rect width="29" height="29" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ClockIcon: React.FC<IconProps> = ({
  color = "white",
  size = 29,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6_769)">
        <path
          d="M14.0469 6.34375C14.7976 6.34375 15.4062 6.95236 15.4062 7.70312V15.2963L19.5393 19.4294C20.0702 19.9603 20.0702 20.821 19.5393 21.3518C19.0085 21.8827 18.1478 21.8827 17.6169 21.3518L13.0857 16.8206C12.8307 16.5657 12.6875 16.2199 12.6875 15.8594V7.70312C12.6875 6.95236 13.2961 6.34375 14.0469 6.34375Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 14.5C0 6.49187 6.49187 0 14.5 0C22.5081 0 29 6.49187 29 14.5C29 22.5081 22.5081 29 14.5 29C6.49187 29 0 22.5081 0 14.5ZM14.5 2.81305C8.04548 2.81305 2.81305 8.04548 2.81305 14.5C2.81305 20.9545 8.04548 26.1869 14.5 26.1869C20.9545 26.1869 26.1869 20.9545 26.1869 14.5C26.1869 8.04548 20.9545 2.81305 14.5 2.81305Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_6_769">
          <rect width="29" height="29" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const GraphIcon: React.FC<IconProps> = ({
  color = "white",
  size = 29,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_6_756"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="32"
      >
        <rect width="32" height="32" fill="white" />
      </mask>
      <g mask="url(#mask0_6_756)">
        <path
          d="M13.6702 7.84579L14.0414 13.3657L14.2257 16.1401C14.2277 16.4255 14.2723 16.7089 14.3586 16.9813C14.5811 17.5101 15.1166 17.8461 15.6991 17.8227L24.5754 17.242C24.9598 17.2357 25.331 17.3795 25.6073 17.6417C25.8375 17.8603 25.9862 18.1461 26.0331 18.4536L26.0489 18.6403C25.6815 23.7265 21.9459 27.9689 16.8702 29.064C11.7944 30.1591 6.58947 27.8457 4.08127 23.3799C3.35818 22.0824 2.90652 20.6564 2.75283 19.1853C2.68863 18.7499 2.66036 18.31 2.6683 17.87C2.66036 12.417 6.54362 7.70262 11.9794 6.5661C12.6337 6.46422 13.275 6.81057 13.5374 7.40738C13.6053 7.54559 13.6501 7.69362 13.6702 7.84579Z"
          fill={color}
        />
        <path
          opacity="0.4"
          d="M29.3337 13.083L29.3244 13.1264L29.2975 13.1896L29.3012 13.3631C29.2873 13.5929 29.1985 13.8139 29.0457 13.9926C28.8864 14.1786 28.6688 14.3053 28.4292 14.3545L28.2831 14.3745L18.042 15.0381C17.7013 15.0717 17.3621 14.9618 17.1089 14.7359C16.8977 14.5475 16.7628 14.2934 16.7247 14.0195L16.0373 3.79334C16.0253 3.75877 16.0253 3.72129 16.0373 3.6867C16.0467 3.40482 16.1708 3.13838 16.3819 2.9469C16.5928 2.75542 16.8733 2.65486 17.1604 2.66769C23.2403 2.82237 28.3501 7.19431 29.3337 13.083Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export const HistoryClockIcon: React.FC<IconProps> = ({
  color = "white",
  size = 29,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 27 27"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6_774)">
        <path
          d="M13.3846 0C20.9042 0 27 6.04416 27 13.5C27 20.9558 20.9042 27 13.3846 27C6.7866 27 1.28477 22.3466 0.0352626 16.1692C-0.330181 14.3624 2.25407 14.2466 2.74323 16.1692C3.94347 20.8881 8.25264 24.381 13.3846 24.381C19.4453 24.381 24.3585 19.5094 24.3585 13.5C24.3585 7.49062 19.4453 2.61905 13.3846 2.61905C10.6836 2.61905 8.12661 3.60724 6.21399 5.21199C6.07294 5.33034 5.78909 5.6348 5.60707 5.83004C5.53643 5.90581 5.48112 5.96513 5.45545 5.99074H7.87263C8.60238 5.99074 9.19395 6.57731 9.19395 7.30087C9.19395 8.02443 8.60238 8.611 7.87263 8.611H2.49032C1.76057 8.611 1.16899 8.02443 1.16899 7.30087V1.54115C1.16899 0.817586 1.76057 0.231022 2.49032 0.231022C3.22007 0.231022 3.81165 0.817586 3.81165 1.54115V3.91528C3.86901 3.85887 4.34621 3.40307 4.40974 3.34782C6.806 1.26353 9.94647 0 13.3846 0Z"
          fill={color}
        />
        <path
          d="M12.9636 5.90625C13.6626 5.90625 14.2292 6.47289 14.2292 7.17188V14.2414L18.0773 18.0894C18.5715 18.5837 18.5715 19.385 18.0773 19.8793C17.583 20.3736 16.7817 20.3736 16.2874 19.8793L12.0686 15.6606C11.8313 15.4232 11.698 15.1013 11.698 14.7656V7.17188C11.698 6.47289 12.2646 5.90625 12.9636 5.90625Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_6_774">
          <rect width="27" height="27" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const PartsIcon: React.FC<IconProps> = ({
  color = "white",
  size = 29,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6_777)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.8871 2.7C10.4061 2.7 10.0161 3.10294 10.0161 3.6V9C10.0161 9.49706 10.4061 9.9 10.8871 9.9H16.1129C16.5939 9.9 16.9839 9.49706 16.9839 9V3.6C16.9839 3.10294 16.5939 2.7 16.1129 2.7H10.8871ZM18.4173 11.7C19.1406 11.0403 19.5968 10.0752 19.5968 9V3.6C19.5968 1.61177 18.037 0 16.1129 0H10.8871C8.96301 0 7.40323 1.61177 7.40323 3.6V9C7.40323 10.0752 7.8594 11.0403 8.58267 11.7C8.62414 11.7378 8.66649 11.7746 8.70968 11.8104C9.30612 12.3045 10.0632 12.6 10.8871 12.6H16.1129C16.9368 12.6 17.6939 12.3045 18.2903 11.8104C18.3335 11.7746 18.3759 11.7378 18.4173 11.7ZM11.0141 15.3C10.9726 15.2622 10.9303 15.2254 10.8871 15.1896C10.2907 14.6955 9.53357 14.4 8.70968 14.4H3.48387C1.55978 14.4 0 16.0118 0 18V23.4C0 25.3882 1.55978 27 3.48387 27H8.70968C10.6338 27 12.1935 25.3882 12.1935 23.4V18C12.1935 16.9248 11.7374 15.9597 11.0141 15.3ZM18.2903 14.4C17.4664 14.4 16.7093 14.6955 16.1129 15.1896C16.0697 15.2254 16.0274 15.2622 15.9859 15.3C15.2626 15.9597 14.8065 16.9248 14.8065 18V23.4C14.8065 25.3882 16.3662 27 18.2903 27H23.5161C25.4402 27 27 25.3882 27 23.4V18C27 16.0118 25.4402 14.4 23.5161 14.4H18.2903ZM3.48387 17.1C3.00285 17.1 2.6129 17.5029 2.6129 18V23.4C2.6129 23.8971 3.00285 24.3 3.48387 24.3H8.70968C9.1907 24.3 9.58065 23.8971 9.58065 23.4V18C9.58065 17.5029 9.1907 17.1 8.70968 17.1H3.48387ZM18.2903 17.1C17.8093 17.1 17.4194 17.5029 17.4194 18V23.4C17.4194 23.8971 17.8093 24.3 18.2903 24.3H23.5161C23.9972 24.3 24.3871 23.8971 24.3871 23.4V18C24.3871 17.5029 23.9972 17.1 23.5161 17.1H18.2903Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_6_777">
          <rect width="27" height="27" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const StudentsIcon: React.FC<IconProps> = ({
  color = "white",
  size = 29,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6_781)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.0151 3.29375C12.4735 2.78579 11.6157 2.52888 10.11 2.52888C8.60427 2.52888 7.74642 2.78579 7.2049 3.29375C6.67692 3.789 5.89749 5.0542 5.89749 8.42959C5.89749 11.805 6.67692 13.0702 7.2049 13.5654C7.74642 14.0734 8.60427 14.3303 10.11 14.3303C11.6157 14.3303 12.4735 14.0734 13.0151 13.5654C13.543 13.0702 14.3225 11.805 14.3225 8.42959C14.3225 5.0542 13.543 3.789 13.0151 3.29375ZM16.85 8.42959C16.85 1.0537 13.48 0 10.11 0C6.73999 0 3.36999 1.0537 3.36999 8.42959C3.36999 15.8055 6.73999 16.8592 10.11 16.8592C13.48 16.8592 16.85 15.8055 16.85 8.42959Z"
          fill={color}
        />
        <path
          d="M13.6906 21.0993H5.05499C3.65909 21.0993 2.52749 22.2315 2.52749 23.6282C2.52749 24.0937 2.90469 24.4711 3.36999 24.4711H12.6375C13.3354 24.4711 13.9012 25.0372 13.9012 25.7356C13.9012 26.4339 13.3354 27 12.6375 27H3.36999C1.5088 27 0 25.4904 0 23.6282C0 20.8348 2.2632 18.5704 5.05499 18.5704H13.6906C14.3885 18.5704 14.9543 19.1365 14.9543 19.8348C14.9543 20.5332 14.3885 21.0993 13.6906 21.0993Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.1004 17.4756C17.3231 16.2779 18.3677 15.4093 19.5853 15.4093H24.8931C26.2107 15.4093 27.2048 16.6061 26.9638 17.9022L25.6564 24.9337C25.4337 26.1314 24.3891 27 23.1715 27H17.8637C16.5461 27 15.5519 25.8032 15.7929 24.5071L17.1004 17.4756ZM24.3863 17.9382H19.5853L18.3705 24.4711H23.1715L24.3863 17.9382Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_6_781">
          <rect width="27" height="27" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const CircleCheckIcon: React.FC<IconProps> = ({
  color = "white",
  size = 29,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6_590)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.5 0C6.49187 0 0 6.49187 0 14.5C0 22.5081 6.49187 29 14.5 29C22.5081 29 29 22.5081 29 14.5C29 6.49187 22.5081 0 14.5 0ZM2.81305 14.5C2.81305 8.04548 8.04548 2.81305 14.5 2.81305C20.9545 2.81305 26.1869 8.04548 26.1869 14.5C26.1869 20.9545 20.9545 26.1869 14.5 26.1869C8.04548 26.1869 2.81305 20.9545 2.81305 14.5Z"
          fill={color}
        />
        <path
          d="M20.3772 9.75344C20.9383 10.2522 20.9889 11.1114 20.4901 11.6726L13.858 19.1337C13.6092 19.4135 13.2561 19.5784 12.8819 19.5894C12.5077 19.6003 12.1455 19.4565 11.8807 19.1918L8.56469 15.8757C8.03382 15.3449 8.03382 14.4842 8.56469 13.9533C9.09556 13.4224 9.95627 13.4224 10.4871 13.9533L12.7838 16.2499L18.4581 9.86633C18.9568 9.3052 19.8161 9.25466 20.3772 9.75344Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_6_590">
          <rect width="29" height="29" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const FailureIcon: React.FC<IconProps> = ({
  color = "white",
  size = 29,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6_593)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.5 0C6.49187 0 0 6.49187 0 14.5C0 22.5081 6.49187 29 14.5 29C22.5081 29 29 22.5081 29 14.5C29 6.49187 22.5081 0 14.5 0ZM2.81305 14.5C2.81305 8.04548 8.04548 2.81305 14.5 2.81305C20.9545 2.81305 26.1869 8.04548 26.1869 14.5C26.1869 20.9545 20.9545 26.1869 14.5 26.1869C8.04548 26.1869 2.81305 20.9545 2.81305 14.5Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.37304 9.37348C9.9039 8.84261 10.7646 8.84261 11.2955 9.37348L14.4996 12.5776L17.7036 9.37348C18.2345 8.84261 19.0952 8.84261 19.6261 9.37348C20.157 9.90435 20.157 10.7651 19.6261 11.2959L16.422 14.5L19.6261 17.7041C20.157 18.235 20.157 19.0957 19.6261 19.6265C19.0952 20.1574 18.2345 20.1574 17.7036 19.6265L14.4996 16.4224L11.2955 19.6265C10.7646 20.1574 9.9039 20.1574 9.37304 19.6265C8.84217 19.0957 8.84217 18.235 9.37304 17.7041L12.5771 14.5L9.37304 11.2959C8.84217 10.7651 8.84217 9.90435 9.37304 9.37348Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_6_593">
          <rect width="29" height="29" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const DocumentIcon: React.FC<IconProps> = ({
  color = "white",
  size = 29,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.6252 6.69975V24.5C23.6252 24.9832 23.2334 25.375 22.7502 25.375H5.25C4.76675 25.375 4.375 24.9832 4.375 24.5L4.37517 3.49999C4.37518 3.01675 4.76693 2.625 5.25017 2.625H19.5504C19.7825 2.625 20.0051 2.71719 20.1691 2.88128L23.3689 6.08103C23.533 6.24513 23.6252 6.46769 23.6252 6.69975ZM26.2502 24.5V6.69975C26.2502 5.77149 25.8814 4.88125 25.2251 4.22487L22.0253 1.02512C21.3689 0.368746 20.4787 0 19.5504 0H5.25017C3.31719 0 1.75019 1.56699 1.75017 3.49997L1.75 24.5C1.74998 26.433 3.31699 28 5.25 28H22.7502C24.6832 28 26.2502 26.433 26.2502 24.5Z"
        fill={color}
      />
      <path
        d="M7.875 14.0068C7.15013 14.0068 6.5625 14.5945 6.5625 15.3193C6.5625 16.0442 7.15013 16.6318 7.875 16.6318H20.1691C20.894 16.6318 21.4816 16.0442 21.4816 15.3193C21.4816 14.5945 20.894 14.0068 20.1691 14.0068H7.875Z"
        fill={color}
      />
      <path
        d="M7.875 19.2568C7.15013 19.2568 6.5625 19.8445 6.5625 20.5693C6.5625 21.2942 7.15013 21.8818 7.875 21.8818H20.1691C20.894 21.8818 21.4816 21.2942 21.4816 20.5693C21.4816 19.8445 20.894 19.2568 20.1691 19.2568H7.875Z"
        fill={color}
      />
      <path
        d="M7.875 8.85307C7.15013 8.85307 6.5625 9.44069 6.5625 10.1656C6.5625 10.8904 7.15013 11.4781 7.875 11.4781H14.0017C14.7265 11.4781 15.3142 10.8904 15.3142 10.1656C15.3142 9.44069 14.7265 8.85307 14.0017 8.85307H7.875Z"
        fill={color}
      />
    </svg>
  );
};
