import { BiLogInCircle } from "react-icons/bi";
import { populateAccount } from "features/accountSlice";
import { Button, Input } from "components/Form";
import useDisclosure from "hooks/useDisclosure";
import { SubmitHandler, useForm } from "react-hook-form";
import { executeLogin } from "apis/auth";
import toast from "react-hot-toast";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useNavigate } from "react-router-dom";

interface LoginProps {
  setActiveStep: (no: any) => void;
}

const StudentLogin: React.FC<LoginProps> = ({ setActiveStep }) => {
  const { register, handleSubmit, getValues, trigger } = useForm<LoginData>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const school = useAppSelector((state) => state.school.selectedSchool!);

  const handleLogin: SubmitHandler<LoginData> = async (data) => {
    try {
      if (!(await trigger())) return;
      onOpen();
      const payload: LoginData = {
        email: getValues("email").trim(),
        password: getValues("password").trim(),
        school: school.id,
      };
      const result = await executeLogin(payload);
      if (result.isSuccess === false) throw new Error(result.message);

      toast.success(result.message);

      dispatch(
        populateAccount({
          user: result.data.user,
          token: result.data.token,
          dashboardModules: result.data.dashboardModules,
          role: result.data.role,
        })
      );
      navigate("/");
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      onClose();
    }
  };

  return (
    <div className="min-h-screen flex justify-center items-center bg-my bg-opacity-75 p-6 ">
      <img
        src={"/images/kano.png"}
        alt="logo"
        className="absolute top-0 left-52 lg:ml-36 object-contain z-10"
        style={{ transform: "translateY(50%)" }}
      />
      <div className="flex flex-col lg:flex-row w-full lg:w-2/4 max-w-5xl bg-white rounded-md shadow-lg relative z-20 lg:ml-auto lg:mr-36">
        <div className="flex-1 flex flex-col items-center lg:items-end p-6 lg:p-0 space-y-2">
          <img
            src={school.logo}
            alt="logo"
            className="w-3/4 h-auto object-contain mt-14 mr-8"
          />
          <div className="text-center lg:text-right">
            <p className="text-lg font-semibold text-gray-800 text-center">
              {school.name}
            </p>
            <div className="flex items-center justify-center">
              <span className="text-sm text-gray-500">{school.address}</span>
            </div>
          </div>
        </div>
        <div className="flex-1 p-8">
          <div className="w-full flex justify-center lg:justify-start mb-6">
            <h1 className="text-lg font-semibold text-gray-800">
              Login
            </h1>
          </div>
          <form onSubmit={handleSubmit(handleLogin)}>
            <Input
              label="Email"
              type="text"
              color={true}
              className="mb-4"
              name={"email"}
              register={register}
            />
            <Input
              label="Password"
              type="password"
              color={true}
              className="mb-4"
              register={register}
              name={"password"}
            />
            <div className="flex justify-between items-center mt-6">
              <Button
                label="Login Now"
                Icon={BiLogInCircle}
                className="bg-red-500 hover:bg-red-600"
                loading={isOpen}
              />
              <button
                type="button"
                className="text-sm underline text-gray-500 hover:text-gray-700"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                Forgot Password
              </button>
            </div>
          </form>
        </div>
      </div>
      <footer className="text-center text-sm text-white p-4 mt-auto absolute z-10 bottom-0">
        <p>Copyright © 2024 Kano State Revenue Agency. All rights reserved.</p>
        <div className="flex justify-center items-center mt-2">
          <span className="mr-1">Powered by</span>
          <img
            src="/images/logo.png"
            alt="logo"
            className="w-14 h-8 object-contain"
          />
        </div>
      </footer>
    </div>
  );
};

export default StudentLogin;
