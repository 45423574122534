import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import "./App.css";
import AppRoutes from "./routes";
import Aos from "aos";

function App() {
  Aos.init();
  const queryClient = new QueryClient();

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <AppRoutes />
      </QueryClientProvider>
    </>
  );
}

export default App;
