import React from "react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { MenuSelect } from "components/Form";

interface TableProps {
  data: any[];
  columns: {
    title: string;
    accessor: string;
    render?: (item: any) => React.ReactNode;
    className?: string;
  }[];
  actions?: {
    title: string;
    icon: React.ElementType;
    onClick: (item: any) => void;
  }[];
}

const CustomTable: React.FC<TableProps> = ({ data, columns, actions }) => {
  const thclasse = "text-start text-sm font-medium py-3 px-2 whitespace-nowrap";
  const tdclasse =
    "text-start text-xs text-gray-900 py-4 px-2 whitespace-nowrap";

  return (
    <table className="w-full">
      <thead className="rounded-md overflow-hidden border-b border-border">
        <tr>
          {columns.map((column, index) => (
            <th
              key={index + column.className!}
              className={`${thclasse} ${column.className || ""}`}
            >
              {column.title}
            </th>
          ))}
          {actions && <th className={thclasse}>Actions</th>}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr
            key={item.id}
            className="border-b border-border hover:bg-greyed transitions"
          >
            {columns.map((column, idx) => (
              <td key={idx} className={`${tdclasse} ${column.className || ""}`}>
                {column.render ? column.render(item) : item[column.accessor]}
              </td>
            ))}
            {actions && (
              <td className={tdclasse}>
                <MenuSelect
                  datas={actions.map((action) => ({
                    title: action.title,
                    icon: action.icon,
                    onClick: () => action.onClick(item),
                  }))}
                >
                  <div className="bg-dry border text-main text-xl py-2 px-4 rounded-lg">
                    <BiDotsHorizontalRounded />
                  </div>
                </MenuSelect>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CustomTable;
