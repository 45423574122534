import { useState, KeyboardEvent, useEffect } from 'react';
import { RiArrowDropDownLine } from "react-icons/ri";

interface School {
  id: number;
  name: string;
  icon: string;
}

const SchoolSearch = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState<School | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);

  const schools: School[] = [
    { id: 1, name: 'University of Lagos', icon: 'https://plus.unsplash.com/premium_photo-1675083923190-387d05e8fe12?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGxvZ298ZW58MHx8MHx8fDA%3D' },
    { id: 2, name: 'Covenant University', icon: 'https://images.unsplash.com/photo-1619679505795-a4d0e6be5e02?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fGxvZ298ZW58MHx8MHx8fDA%3D' },
    { id: 3, name: 'Obafemi Awolowo University', icon: 'https://images.unsplash.com/photo-1562783912-21ad31ee2a83?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fGxvZ298ZW58MHx8MHx8fDA%3Dg' },
  ];

  const filteredSchools = schools.filter((school) =>
    school.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (school: School) => { 
    setSelectedSchool(school);
    setSearchTerm(school.name); 
    setIsOpen(false); 
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    if (!isOpen) {
      setIsOpen(true); 
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === 'Return') {
      e.preventDefault();
      if (focusedIndex !== null && filteredSchools[focusedIndex]) {
        handleOptionClick(filteredSchools[focusedIndex]);
      }
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      setFocusedIndex((prev) => prev === null ? 0 : Math.min(filteredSchools.length - 1, prev + 1));
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setFocusedIndex((prev) => prev === null ? filteredSchools.length - 1 : Math.max(0, prev - 1));
    }
  };

  useEffect(() => {
    setFocusedIndex(null); 
  }, [searchTerm, filteredSchools]);

  return (
    <div className="relative w-full">
      <input
        type="text"
        onFocus={handleDropdownClick}
        value={selectedSchool?.name || searchTerm}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        className="cursor-pointer border border-gray-300 rounded-md p-2 bg-gray-200 w-full pr-10" // Add padding-right for icon
        placeholder="Search for a school"
      />
      <RiArrowDropDownLine 
        onClick={handleDropdownClick}
        className="absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-500 text-xl cursor-pointer" 
      />
      {isOpen && (
        <div className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg">
          <ul>
            {filteredSchools.map((school, index) => (
              <li
                key={school.id}
                onClick={() => handleOptionClick(school)}
                className={`cursor-pointer px-4 py-2 flex items-center hover:bg-gray-100 ${focusedIndex === index ? 'bg-gray-200' : ''}`}
                onMouseEnter={() => setFocusedIndex(index)}
              >
                <img
                  src={school.icon}
                  alt={school.name}
                  className="w-6 h-6 mr-3"
                />
                {school.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SchoolSearch;
