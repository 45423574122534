import { useState } from "react";
import SelectSchool from "./Auth/SelectSchools";
import StudentLogin from "./Auth/StudentLogin";

const Login = () => {
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    {
      name: "Validate Email",
      component: <SelectSchool setActiveStep={setActiveStep} />,
    },
    {
      name: "Verify Email",
      component: <StudentLogin setActiveStep={setActiveStep} />,
    },
  ];

  return <> {steps[activeStep].component}</>;
};

export default Login;
