import Layout from "../../Layout";
import { FiBarChart } from "react-icons/fi";
import { BiNotepad } from "react-icons/bi";
import { TbAlignBoxLeftTop } from "react-icons/tb";
import { useAppSelector } from "hooks/redux";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { executeGetStudentDashboard } from "apis/dashboard";
import useDisclosure from "hooks/useDisclosure";
import Loader from "components/Notifications/Loader";

// Define types for the components
interface SummaryCardProps {
  icon: React.ReactNode;
  label: string;
  value: string | number;
  amount: string | number;
  bgColor: string;
  iconColor: string;
}

interface SummaryData {
  totalPaidInvoices: number;
  totalPaidAmount: number;
  totalUnpaidInvoices: number;
  totalUnpaidAmount: number;
}

interface PaymentSummaryProps {
  data: SummaryData;
}

const SummaryCard = ({
  icon,
  label,
  value,
  amount,
  bgColor,
  iconColor,
}: SummaryCardProps) => (
  <div className={`${bgColor} rounded-lg h-auto p-4 flex-grow`}>
    <div className="flex flex-col items-start">
      <div
        className={`${iconColor} w-12 h-12 text-white rounded-full flex items-center justify-center mb-4`}
      >
        {icon}
      </div>
      <h3 className="text-2xl font-semibold mb-2">{value}</h3>
      <p className="text-gray-600 mb-2">{label}</p>
      <p className="text-blue-600 mb-2">{amount} NGN</p>
    </div>
  </div>
);

const PaymentSummary: React.FC<PaymentSummaryProps> = ({ data }) => {
  const summaryData = [
    {
      icon: <FiBarChart />,
      label: "Total",
      value: data.totalPaidInvoices + data.totalUnpaidInvoices || 0,
      amount: data.totalPaidAmount + data.totalUnpaidAmount || 0,
      bgColor: "bg-red-100",
      iconColor: "bg-red-500",
    },
    {
      icon: <BiNotepad />,
      label: "Pending",
      value: data.totalUnpaidInvoices || 0,
      amount: data.totalUnpaidAmount || "0.00",
      bgColor: "bg-yellow-50",
      iconColor: "bg-orange-400",
    },
    {
      icon: <TbAlignBoxLeftTop />,
      label: "Paid",
      value: data.totalPaidInvoices || 0,
      amount: data.totalPaidAmount || "0.00",
      bgColor: "bg-green-100",
      iconColor: "bg-green-400",
    },
  ];

  return (
    <div className="p-6 bg-white rounded-lg shadow-md w-full lg:w-2/3">
      <h2 className="text-lg font-semibold text-indigo-900 mb-2">
        Payments Summary
      </h2>
      <p className="text-gray-500 mb-6">Paypro Analytics</p>
      <div className="w-full flex gap-4">
        {summaryData.map((item, index) => (
          <SummaryCard
            key={index}
            icon={item.icon}
            label={item.label}
            value={item.value}
            amount={item.amount}
            bgColor={item.bgColor}
            iconColor={item.iconColor}
          />
        ))}
      </div>
    </div>
  );
};

const PendingPayments = () => (
  <div className="flex justify-between p-6 items-center bg-white rounded-lg shadow-md w-full lg:w-2/3">
    <div className="">
      <h2 className="text-lg font-semibold text-indigo-900 mb-1">
        Payments Summary
      </h2>
      <p className="text-gray-500 ">Total Pending Payments</p>
    </div>
    <div className="px-5 py-2 grid place-items-center text-black text-3xl bg-[#F3E8FF] rounded-2xl">
      0
    </div>
  </div>
);

function StudentDashboard() {
  const { firstName } = useAppSelector((state) => state.account.user);
  const token = useAppSelector((state) => state.account.token.value);
  const { onOpen, onClose } = useDisclosure();
  const [data, setData] = useState<SummaryData | null>(null);

  const fetchStudentDashboard = async () => {
    try {
      onOpen();
      const result = await executeGetStudentDashboard(token);
      setData(result.data);
      onClose();
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    fetchStudentDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <div className="flex flex-wrap flex-col lg:flex-nowrap gap-4">
        <h1 className="text-3xl font-semibold text-[#151D48] my-4">
          Welcome {firstName}
        </h1>
        <PendingPayments />
        {data ? <PaymentSummary data={data} /> : <Loader />}
      </div>
    </Layout>
  );
}

export default StudentDashboard;
