import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserData {
  id?: number;
  employeeId?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  title?: string;
  phoneNumber?: string;
  roleId?: number;
  instruction?: string;
  access?: any;
  image?: string;
  loading?: boolean;
  hospitalId?: number | null;
}

interface TokenData {
  [key: string]: string;
}

interface RoleData {
  createdAt: string;
  description: string;
  id: number;
  roleName: string;
  updatedAt: string;
}

interface AccountState {
  user: UserData;
  token: TokenData;
  dashboardModules: string[];
  students: StudentData[];
  isAuthenticated: boolean;
  role: RoleData;
}

const initialState: AccountState = {
  user: {
    id: 0,
    employeeId: "",
    email: "",
    firstName: "",
    lastName: "",
    title: "",
    phoneNumber: "",
    roleId: 0,
    instruction: "",
    access: {},
    image: "",
    loading: false,
    hospitalId: null,
  },
  token: {
    token: "",
    refreshToken: "",
  },
  dashboardModules: [],
  role: {
    createdAt: "",
    description: "",
    id: 0,
    roleName: "",
    updatedAt: "",
  },
  students: [],
  isAuthenticated: false,
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    populateAccount(state, action: PayloadAction<any>) {
      state.user = { ...state.user, ...action.payload.user };
      state.dashboardModules = action.payload.dashboardModules;
      state.token = { type: "token", value: action.payload.token };
      state.role = action.payload.role;
      state.isAuthenticated = true;
    },
    // setUser(state, action: PayloadAction<UserData>) {
    //   state.user = { ...state.user, ...action.payload.user };
    // },
    // setToken(state, action: PayloadAction<string>) {
    //   state.token = { type: "token", value: action.payload };
    //   state.isAuthenticated = true;
    // },
    // setDashboardModules(state, action: PayloadAction<string[]>) {
    //   state.dashboardModules = action.payload;
    // },
    clearAccount(state) {
      state.user = initialState.user;
      state.token = initialState.token;
      state.dashboardModules = [];
      state.students = [];
      state.isAuthenticated = false;
      localStorage.clear();
    },
    populatestudents(state, action: PayloadAction<StudentData[]>) {
      state.students = action.payload;
    },
  },
});

export const {
  // setUser,
  // setToken,
  clearAccount,
  // setDashboardModules,
  populateAccount,
  populatestudents,
} = accountSlice.actions;
export default accountSlice.reducer;
