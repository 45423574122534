import Layout from "../../Layout";
import { PiLineVertical } from "react-icons/pi";
import { FiBarChart } from "react-icons/fi";
import { BiNotepad } from "react-icons/bi";
import { TbAlignBoxLeftTop, TbClockHour4 } from "react-icons/tb";
import { LineChart, BarChart, Donut, PieChart } from "../../components/Charts";
import { useAppSelector } from "hooks/redux";

interface SummaryCardProps {
  icon: React.ReactNode;
  label: string;
  value: string;
  amount: string;
  bgColor: string;
  iconColor: string;
}

interface LabelWithTotalProps {
  label: string;
  color: string;
  total: string;
}

const PaymentSummary = () => (
  <div className="p-6 bg-white rounded-lg shadow-md w-full lg:w-2/3">
    <h2 className="text-lg font-semibold text-indigo-900 mb-2">
      Payments Summary
    </h2>
    <p className="text-gray-500 mb-6">Paypro Analytics</p>
    <div className="w-full flex gap-4">
      <SummaryCard
        icon={<FiBarChart />}
        label="Today"
        value="0"
        amount="0.00 NGN"
        bgColor="bg-red-100"
        iconColor="bg-red-500"
      />
      <SummaryCard
        icon={<BiNotepad />}
        label="This Week"
        value="0"
        amount="0.00 NGN"
        bgColor="bg-yellow-50"
        iconColor="bg-orange-400"
      />
      <SummaryCard
        icon={<TbAlignBoxLeftTop />}
        label="This Month"
        value="0"
        amount="0.00 NGN"
        bgColor="bg-green-100"
        iconColor="bg-green-400"
      />
      <SummaryCard
        icon={<TbClockHour4 />}
        label="All Time"
        value="0"
        amount="0.00 NGN"
        bgColor="bg-purple-100"
        iconColor="bg-purple-400"
      />
    </div>
  </div>
);

const SummaryCard = ({
  icon,
  label,
  value,
  amount,
  bgColor,
  iconColor,
}: SummaryCardProps) => (
  <div className={`${bgColor} rounded-lg h-auto p-4 flex-grow`}>
    <div className="flex flex-col items-start">
      <div
        className={`${iconColor} w-12 h-12 text-white rounded-full flex items-center justify-center mb-4`}
      >
        {icon}
      </div>
      <h3 className="text-2xl font-semibold mb-2">{value}</h3>
      <p className="text-gray-600 mb-2">{label}</p>
      <p className="text-blue-600 mb-2">{amount}</p>
    </div>
  </div>
);

const LeadSummary = () => (
  <div className="w-full lg:w-1/3 bg-white rounded-lg shadow-md p-6">
    <h2 className="text-lg font-semibold text-indigo-900 mb-2">
      Lead (This Month)
    </h2>
    <div className="flex items-center justify-center">
      <Donut data={[]} colors={[]} />
    </div>
    <hr className="mt-8" />
    <div className="flex justify-around mt-4">
      <LabelWithTotal label="Web Pay" total="5,000" color="bg-blue-500" />
      <PiLineVertical className="text-slate-300" />
      <LabelWithTotal label="Bank Branch" total="504" color="bg-red-500" />
    </div>
  </div>
);

const LabelWithTotal = ({ label, total, color }: LabelWithTotalProps) => (
  <div className="flex flex-col items-center">
    <div className="flex items-center">
      <span
        className={`inline-block w-3 h-3 ${color} rounded-full mr-2`}
      ></span>
      <p className="text-slate-600">{label}</p>
    </div>
    <p className="text-lg text-slate-800 mt-2">{total}</p>
  </div>
);

const DemographicChart = () => (
  <div className="p-6 bg-white rounded-lg shadow-md lg:w-3/5">
    <h2 className="text-lg font-semibold text-indigo-900 mb-2">Demographic</h2>
    <div className="flex items-center justify-center w-full space-x-4">
      <BarChart />
    </div>
  </div>
);

const WeeklyPaymentTrend = () => (
  <div className="w-full lg:w-2/5 bg-white rounded-lg shadow-md p-6">
    <h2 className="text-lg font-semibold text-indigo-900 mb-2">
      Weekly Payment Trend
    </h2>
    <div className="flex items-center justify-center w-full space-x-4">
      <LineChart />
    </div>
  </div>
);

const TopPayingSchools = () => (
  <div className="p-6 bg-white rounded-lg shadow-md lg:w-3/5">
    <h2 className="text-lg font-semibold text-indigo-900 mb-2">
      Top Paying Schools
    </h2>
    <div className="flex items-center justify-center w-full space-x-4">
      <table className="w-full table-auto border-collapse">
        <thead>
          <tr className="bg-gray-100 border-b">
            <th className="text-left p-4 text-sm font-semibold text-gray-600">
              #
            </th>
            <th className="text-left p-4 text-sm font-semibold text-gray-600">
              School Name
            </th>
            <th className="text-left p-4 text-sm font-semibold text-gray-600">
              Fees Collected
            </th>
            <th className="text-left p-4 text-sm font-semibold text-gray-600">
              Students
            </th>
          </tr>
        </thead>
        <tbody>
          {topSchools.map((school, index) => (
            <tr key={index} className="hover:bg-gray-50 border-b">
              <td className="p-4 text-gray-700">{school.id}</td>
              <td className="p-4 text-gray-700">{school.name}</td>
              <td className="p-4">
                <progress
                  value={school.progress}
                  max={1}
                  className="w-full h-2 bg-gray-200 rounded-lg"
                >
                  <div
                    className="h-full bg-green-500 rounded-lg"
                    style={{ width: `${school.progress * 100}%` }}
                  ></div>
                </progress>
              </td>
              <td className="p-4">
                <p className="inline-block px-3 py-1 border border-gray-300 rounded-lg text-gray-700">
                  {school.students}
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

const topSchools = [
  { id: "01", name: "Green Valley High School", progress: 0.75, students: 400 },
  { id: "02", name: "Blue Ridge Academy", progress: 0.65, students: 300 },
  { id: "03", name: "Riverdale School", progress: 0.85, students: 500 },
  { id: "04", name: "Sunrise High", progress: 0.9, students: 600 },
];

const SchoolsChart = () => (
  <div className="w-full lg:w-2/5 bg-white rounded-lg shadow-md p-6 mx-auto">
    <h2 className="text-lg font-semibold text-indigo-900 mb-2">Schools</h2>
    <div className="flex items-center justify-center">
      <PieChart />
    </div>
    <hr className="mt-8" />
    <div className="flex flex-col items-center mt-4">
      <div className="flex items-center">
        <span className="inline-block w-3 h-3 bg-blue-500 rounded-full mr-2"></span>
        <p className="text-slate-600">All Schools</p>
      </div>
    </div>
  </div>
);

function ExecutiveDashboard() {
  const { firstName } = useAppSelector((state) => state.account.user);

  return (
    <Layout>
      <h1 className="text-3xl font-semibold text-[#151D48] my-4">
        Welcome {firstName}
      </h1>
      <div className="flex flex-wrap lg:flex-nowrap gap-4">
        <PaymentSummary />
        <LeadSummary />
      </div>
      <div className="flex flex-wrap lg:flex-nowrap gap-4 mt-8">
        <DemographicChart />
        <WeeklyPaymentTrend />
      </div>
      <div className="flex flex-wrap lg:flex-nowrap gap-4 mt-8">
        <TopPayingSchools />
        <SchoolsChart />
      </div>
    </Layout>
  );
}

export default ExecutiveDashboard;
