import React, { useState } from "react";
import { IoCloudUploadOutline, IoClose } from "react-icons/io5";
import { handleConvertCSVToArray, readFile } from "utils/index";
import useDisclosure from "hooks/useDisclosure";
import { executeRegisterMultipleStudent } from "apis/students";
import toast from "react-hot-toast";
import { Button } from "components/Form";
import { useAppSelector } from "hooks/redux";

// interface StudentFormData {
//   name: string;
//   studentId: string;
//   email: string;
//   address: string;
//   faculty: string;
//   department: string;
//   level: string;
//   gender: string;
//   phone: string;
// }

interface CreateStudentFormProps {
  onCloseForm: () => void;
}

const CreateStudentForm: React.FC<CreateStudentFormProps> = ({
  onCloseForm,
}) => {
  // const [formData, setFormData] = useState<StudentFormData>({
  //   name: "",
  //   studentId: "",
  //   email: "",
  //   address: "",
  //   faculty: "",
  //   department: "",
  //   level: "",
  //   gender: "Male",
  //   phone: "",
  // });

  const [fileName, setFileName] = useState("");
  const [csvData, setCsvData] = useState<any[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const school = useAppSelector((state) => state.school?.selectedSchool?.id);

  // const handleChange = (
  //   e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  // ) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    setFileName(e.target.files[0].name);
    const csvText = await readFile(e.target.files[0]);
    const arr = handleConvertCSVToArray(csvText as string);
    setCsvData(arr);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      onOpen();

      // Add is_student: true to all objects in csvData
      const updatedCsvData = csvData.map((item) => ({
        ...item,
        is_student: true,
        school_id: school,
        role_id: 2,
      }));

      // Send the updated data
      const result = await executeRegisterMultipleStudent({
        users: updatedCsvData,
      });

      if (result.isSuccess === false) throw new Error(result.message);
      toast.success(result.message);
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      onClose();
      onCloseForm();
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="p-6 mb-20 w-full max-w-6xl bg-white rounded-lg shadow-md ml-52 mt-36">
        <div className="flex items-center justify-between mb-3">
          <h2 className="text-xl font-bold text-gray-800">
            Create New Student
          </h2>
          <button
            type="button"
            onClick={onCloseForm}
            className="text-gray-600 hover:text-gray-800"
          >
            <IoClose size={24} />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-6">
          {/* <div className="grid grid-cols-2 gap-4">
            <div className="col-span-2 mb-3">
              <label className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Name"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>

            <div className="mb-3">
              <label className="block text-sm font-medium text-gray-700">
                Matric No
              </label>
              <input
                type="text"
                name="studentId"
                value={formData.studentId}
                onChange={handleChange}
                placeholder="Matriculation no."
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="mb-3">
              <label className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="mail"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="col-span-2 mb-3">
              <label className="block text-sm font-medium text-gray-700">
                Address
              </label>
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                placeholder="Address"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="mb-3">
              <label className="block text-sm font-medium text-gray-700">
                Faculty
              </label>
              <input
                type="text"
                name="faculty"
                value={formData.faculty}
                onChange={handleChange}
                placeholder="Life Science"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="mb-3">
              <label className="block text-sm font-medium text-gray-700">
                Department
              </label>
              <input
                type="text"
                name="department"
                value={formData.department}
                onChange={handleChange}
                placeholder="micro-biology"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="mb-3">
              <label className="block text-sm font-medium text-gray-700">
                Level
              </label>
              <input
                type="text"
                name="level"
                value={formData.level}
                onChange={handleChange}
                placeholder="Level"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="mb-3">
              <label className="block text-sm font-medium text-gray-700">
                Gender
              </label>
              <select
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="mb-3">
              <label className="block text-sm font-medium text-gray-700">
                Phone
              </label>
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Phone"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
          </div> */}

          <div className="bg-gray-200 w-full rounded-lg p-6 flex flex-col items-center relative">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              File Upload
            </label>
            <input
              type="file"
              accept=".csv, .xlsx"
              onChange={handleFileUpload}
              className="hidden"
              id="file-upload"
            />
            <label
              htmlFor="file-upload"
              className="cursor-pointer inline-flex items-center px-4 py-2 border border-green-500 rounded-md text-green-500 mt-4 bg-transparent hover:bg-green-50"
            >
              <IoCloudUploadOutline className="mr-2" />
              Upload CSV
            </label>
            {fileName && (
              <p className="mt-2 text-sm text-gray-600">
                {fileName} - uploaded
              </p>
            )}
          </div>

          <div className="col-span-3 flex justify-center mt-3">
            <Button loading={isOpen} label="Submit" onClick={handleSubmit} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateStudentForm;
