import { Button } from "components/Form";
import React, { useState, useEffect, useMemo } from "react";
import { FaTimes } from "react-icons/fa";
import Select, { MultiValue, StylesConfig, ActionMeta } from "react-select";

interface Category {
  id: string;
  name: string;
}

interface Faculty {
  id: string;
  name: string;
}

interface Department {
  id: string;
  name: string;
}

interface Level {
  id: string;
  name: string;
}

interface SelectOption {
  value: string;
  label: string;
}

interface PaymentItem {
  id?: string;
  name?: string;
  amount?: string | number;
  departments?: string[];
  faculties?: string[];
  levels?: string[];
  due_date?: string;
  payment_category?: string;
  [key: string]: any;
}

interface FormData {
  paymentType: string;
  faculties: SelectOption[];
  departments: SelectOption[];
  amount: string;
  levels: SelectOption[];
  dueDate: string;
}

interface PaymentItemFormProps {
  closeModal: () => void;
  isOpen: boolean;
  paymentItem: PaymentItem;
  categories: Category[];
  departments: Department[];
  faculties: Faculty[];
  onSubmit?: (data: FormData) => void;
  isLoading: boolean;
}

const selectStyles: StylesConfig<SelectOption, true> = {
  control: (styles) => ({
    ...styles,
    padding: "0.375rem",
    borderRadius: "0.5rem",
    border: "1px solid #e2e8f0",
    boxShadow: "none",
    "&:hover": {
      border: "1px solid #e2e8f0",
    },
  }),
  menu: (styles) => ({
    ...styles,
    borderRadius: "0.5rem",
    overflow: "hidden",
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isSelected ? "#EF4444" : isFocused ? "#FEE2E2" : "white",
    color: isSelected ? "white" : "#1F2937",
    ":active": {
      backgroundColor: "#EF4444",
      color: "white",
    },
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: "#FEE2E2",
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: "#EF4444",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "#EF4444",
    ":hover": {
      backgroundColor: "#EF4444",
      color: "white",
    },
  }),
};

const PaymentItemForm: React.FC<PaymentItemFormProps> = ({
  closeModal,
  isOpen,
  paymentItem = {},
  categories = [],
  departments = [],
  faculties = [],
  isLoading,
  onSubmit,
}) => {
  const [formData, setFormData] = useState<FormData>({
    paymentType: "",
    faculties: [],
    departments: [],

    amount: "",
    levels: [],
    dueDate: "",
  });

  const levels = useMemo<Level[]>(
    () => [
      { name: "100", id: "100" },
      { name: "200", id: "200" },
      { name: "300", id: "300" },
      { name: "400", id: "400" },
    ],
    []
  );

  useEffect(() => {
    if (paymentItem) {
      setFormData({
        paymentType: paymentItem.payment_category || "",
        faculties: (paymentItem.faculties || []).map((id) => {
          const faculty = faculties.find((f) => f.id === id);
          return { value: id, label: faculty?.name || id };
        }),
        departments: (paymentItem.departments || []).map((id) => {
          const department = departments.find((d) => d.id === id);
          return { value: id, label: department?.name || id };
        }),
        amount: paymentItem.amount?.toString() || "",
        levels: (paymentItem.levels || []).map((id) => {
          const level = levels.find((l) => l.id === id);
          return { value: id, label: level?.name || id };
        }),
        dueDate: paymentItem.due_date || "",
      });
    }
  }, [paymentItem, departments, faculties, levels]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    console.log({ formData });
    e.preventDefault();
    onSubmit?.(formData);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelectChange = (
    field: keyof Pick<FormData, "faculties" | "departments" | "levels">,
    value: MultiValue<SelectOption>,
    _: ActionMeta<SelectOption>
  ) => {
    const allOption = value.find((option) => option.value === "all");

    if (allOption) {
      const allOptions =
        field === "faculties"
          ? faculties.map((faculty) => ({
              value: faculty.id,
              label: faculty.name,
            }))
          : field === "departments"
          ? departments.map((dept) => ({
              value: dept.id,
              label: dept.name,
            }))
          : levels.map((level) => ({
              value: level.id,
              label: level.name,
            }));

      setFormData((prev) => ({
        ...prev,
        [field]: allOptions,
      }));
    } else {
      // Update selected values 
      setFormData((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
  };

  console.log({ categories });

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg w-full max-w-2xl max-h-[80vh] overflow-y-auto p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold text-navy-900">
            {paymentItem.id ? "Edit Payment Item" : "Create New Payment Item"}
          </h1>
          <button
            onClick={closeModal}
            type="button"
            className="text-gray-500 hover:text-gray-700"
          >
            <FaTimes size={24} />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-navy-900 font-semibold mb-2">
              Payment Type
            </label>
            <select
              className="w-full p-3 border rounded-lg bg-white appearance-none pr-10 relative"
              name="paymentType"
              value={formData.paymentType}
              onChange={handleInputChange}
            >
              <option value="">Level Fees</option>
              {categories.map((cat) => (
                <option key={cat.id} value={cat.id}>
                  {cat.name}
                </option>
              ))}
            </select>
          </div>

          <div className="grid grid-cols-1 gap-4">
            <div>
              <label className="block text-navy-900 font-semibold mb-2">
                Faculty
              </label>
              <Select<SelectOption, true>
                isMulti
                name="faculties"
                options={[
                  { value: "all", label: "All" },
                  ...faculties.map((faculty) => ({
                    value: faculty.id,
                    label: faculty.name,
                  })),
                ]}
                value={formData.faculties}
                onChange={(newValue, actionMeta) =>
                  handleSelectChange("faculties", newValue, actionMeta)
                }
                styles={selectStyles}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>

            <div>
              <label className="block text-navy-900 font-semibold mb-2">
                Department
              </label>
              <Select<SelectOption, true>
                isMulti
                name="departments"
                options={[
                  { value: "all", label: "All" },
                  ...departments.map((dept) => ({
                    value: dept.id,
                    label: dept.name,
                  })),
                ]}
                value={formData.departments}
                onChange={(newValue, actionMeta) =>
                  handleSelectChange("departments", newValue, actionMeta)
                }
                styles={selectStyles}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>

            <div>
              <label className="block text-navy-900 font-semibold mb-2">
                Level
              </label>
              <Select<SelectOption, true>
                isMulti
                name="levels"
                options={[
                  { value: "all", label: "All" },
                  ...levels.map((level) => ({
                    value: level.id,
                    label: level.name,
                  })),
                ]}
                value={formData.levels}
                onChange={(newValue, actionMeta) =>
                  handleSelectChange("levels", newValue, actionMeta)
                }
                styles={selectStyles}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
          </div>

          <div>
            <label className="block text-navy-900 font-semibold mb-2">
              Amount
            </label>
            <input
              className="w-full p-3 border rounded-lg"
              type="text"
              name="amount"
              value={formData.amount}
              onChange={handleInputChange}
              placeholder="Enter amount"
            />
          </div>

          <div>
            <label className="block text-navy-900 font-semibold mb-2">
              Due Date
            </label>
            <input
              className="w-full p-3 border rounded-lg"
              type="date"
              name="dueDate"
              value={formData.dueDate}
              onChange={handleInputChange}
            />
          </div>

          <div className="text-right">
            <Button
              type="submit"
              label=" Save Payment Item"
              loading={isLoading}
              className="px-6 py-3 bg-red-600 text-white font-semibold rounded-lg hover:bg-red-700 transition-colors"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default PaymentItemForm;
