import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Button, Input } from "../../components/Form";
import { BiLogInCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { setPassword } from "../../apis/auth";
import { useAppSelector } from "hooks/redux";
// import { showErrors } from "../../utils";
// import { useAppSelector } from "../../app/hooks";

interface SetPasswordForm {
  password: string;
  confirmPassword: string;
}

const SetUserPassword: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SetPasswordForm>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const tokenStore = useAppSelector((state) => state.account.token);
  const email = useAppSelector((state) => state.account.user.email);

  console.log({ email });

  const handleSetPassword: SubmitHandler<SetPasswordForm> = async (data) => {
    if (!email) return;
    if (data.password !== data.confirmPassword) {
      toast.error("Passwords do not match. Please try again.");
      return;
    }

    setLoading(true);

    try {
      const response = await setPassword({
        email,
        password: data.password,
        token: tokenStore.value,
      });

      console.log("API Response:", response);

      if (response.status === "success") {
        toast.success(response.message);
        navigate("/login");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-screen flex-colo bg-dry">
      <form
        className="w-2/5 p-8 rounded-2xl mx-auto bg-white flex-colo"
        onSubmit={handleSubmit(handleSetPassword)}
      >
        <img
          src="/images/kano.png"
          alt="logo"
          className="w-48 h-16 object-contain"
        />
        <div className="flex flex-col gap-4 w-full mb-6">
          <Input
            label="New Password"
            type="password"
            color={true}
            placeholder="*********"
            register={register}
            name="password"
            required
          />
          {errors.password && (
            <p className="text-red-500 text-xs mt-1">
              {errors.password.message}
            </p>
          )}
          <Input
            label="Confirm Password"
            type="password"
            color={true}
            placeholder="*********"
            register={register}
            name="confirmPassword"
            required
          />
          {errors.confirmPassword && (
            <p className="text-red-500 text-xs mt-1">
              {errors.confirmPassword.message}
            </p>
          )}
        </div>
        <Button label="Set Password" Icon={BiLogInCircle} loading={loading} />
      </form>
    </div>
  );
};

export default SetUserPassword;
