import axios from "axios";
import { STUDENT_DASHBOARD } from ".";

export const executeGetStudentDashboard = async (
  token: string
): Promise<ResponseDataType> => {
  try {
    const options = {
      url: STUDENT_DASHBOARD,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = (await axios(options)) satisfies ResponseDataType;
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: error };
  }
};
