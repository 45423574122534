import React from "react";
import Chart from "react-apexcharts";

export function PieChart() {
  const series = [10, 10, 10, 10, 10, 10, 10, 10];
  const options = {
    chart: {
      width: 380,
      type: "donot",
    },
    labels: [
      "Nutrition and dietictics",
      "Public Health",
      "Health information management",
      "Dispensary and opticianry",
      "Environmental health",
      "Epidemiology and disease control",
      "Medical laboratory",
      "Consultancy service",
    ],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return <Chart options={options} series={series} type="donut" width={380} />;
}

export function LineChart() {
  const series = [
    {
      name: "High - 2024",
      data: [28, 29, 33, 36, 32, 32, 33],
    },

    {
      name: "Low - 2024",
      data: [12, 11, 14, 18, 17, 13, 13],
    },
  ];
  const options = {
    chart: {
      height: 350,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#2196F3", "#f56565"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: ["Mon", "Tues", "Wed", "Thurs", "Fri", "Sat", "Sun"],
    },

    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
  };

  return (
    <div>
      <div id="chart">
        <Chart options={options} series={series} type="line" height={350} />
      </div>
    </div>
  );
}

export function BarChart() {
  const series = [
    {
      name: "male",
      data: [20, 10, 20, 30, 20, 10, 50],
    },
    {
      name: "Female",
      data: [10, 20, 10, 20, 10, 20, 10],
    },
  ];
  const options = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: ["Mon", "Tues", "Wed", "Thurs", "Fri", "Sat", "Sun"],
    },
    fill: {
      opacity: 1,
    },
    colors: ["#2196F3", "#f56565"],
    tooltip: {
      y: {
        formatter: function (val) {
          return val + "k";
        },
      },
    },
  };

  return (
    <div>
      <div id="chart">
        <Chart
          options={options}
          series={series}
          type="bar"
          width="100%"
          height={300}
        />
      </div>
    </div>
  );
}

export function Donut({ data, colors }) {
  const options = {
    chart: {
      type: "donut",
    },
    colors: ["#2196F3", "#f56565"],
    labels: ["Web Pay", "Bank Branch"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const series = [84, 42];

  return <Chart options={options} series={series} type="donut" width="100%" />;
}

export function DashboardSmallChart({ data, colors }) {
  const options = {
    chart: {
      id: "basic-bar",
      sparkline: {
        enabled: true,
      },
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 400,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 150,
        },
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },

    yaxis: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div className="bg-white py-2 px-2 text-xs border-[.5px] border-border">' +
          "Total:" +
          " " +
          '<span className="font-semibold">' +
          series[seriesIndex][dataPointIndex] +
          "</span>" +
          "</div>"
        );
      },
    },
    grid: {
      show: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "75%",
        distributed: false,
        borderRadius: 2,
      },
    },
    colors: [colors],
  };
  const series = [
    {
      name: "series-1",
      data: data,
    },
  ];

  return (
    <Chart
      options={options}
      series={series}
      type="bar"
      width="100%"
      height={50}
    />
  );
}

export function DashboardBigChart() {
  const options = {
    chart: {
      id: "area-datetime",
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 1000,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 800,
        },
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        show: true,
        style: {
          colors: "#A0A0A0",
          fontSize: "12px",
          fontWeight: 400,
        },
      },
      axisTicks: {
        show: false,
      },
    },

    yaxis: {
      show: true,
      labels: {
        show: true,
        style: {
          colors: "#A0A0A0",
          fontSize: "10px",
          fontWeight: 400,
        },
        formatter: function (value) {
          return value + "k";
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div className="bg-white py-2 px-2 text-xs border-[.5px] border-border">' +
          "Total:" +
          " " +
          '<span className="font-semibold">' +
          series[seriesIndex][dataPointIndex] +
          "</span>" +
          "</div>"
        );
      },
    },
    grid: {
      show: true,
      borderColor: "#E8EBEE",
      strokeDashArray: 4,
      position: "back",
    },
    stroke: {
      curve: "smooth",
      width: 1,
    },

    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0.5,
        inverseColors: true,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 100],
      },
    },
    colors: ["#66B5A3"],
  };
  const series = [
    {
      name: "Total",
      data: [30, 40, 25, 50, 49, 21, 70, 51, 42, 60, 40, 20],
    },
  ];

  return (
    <Chart
      options={options}
      series={series}
      type="area"
      width="100%"
      height={300}
    />
  );
}
