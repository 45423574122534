import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { executeGetExtTransactionsDetails } from "apis/schools";
import { Button } from "components/Form";
import Loader from "components/Notifications/Loader";
import { useAppSelector } from "hooks/redux";
import { IoCalendarNumber } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";

interface FormData {
  name?: string;
  regNumber?: string;
  department?: string;
  level?: string;
  faculty?: string;
  txn_ref: string;
  amount: number;
  merchant_code: string;
  pay_item_id: string;
  currency: string;
  site_redirect_url: string;
}

const ExtPaymentSummary: React.FC = () => {
  const { id } = useParams();
  const [selectedMethod, setSelectedMethod] = useState<string | null>(null);
  const token = useAppSelector((state) => state.account.token.value);
  const [formData, setFormData] = useState<FormData | null>(null);

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["extTransactionDetails", token],
    queryFn: () =>
      executeGetExtTransactionsDetails({
        queryKey: ["extTransactionDetails", id!],
        token,
      }),
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      setFormData({
        name: `${data?.firstName} ${data?.lastName}`,
        regNumber: data?.regNo,
        department: data?.department,
        level: data?.level,
        faculty: data.faculty,
        txn_ref: data?.txnRef,
        amount: data.amount * 100,
        merchant_code: process.env.REACT_APP_MERCHANT_CODE!,
        pay_item_id: process.env.REACT_APP_PAYMENT_ITEM_ID!,
        currency: "566",
        site_redirect_url: `${process.env.REACT_APP_API_URL}/api/v1/payment-confirmation`,
      });
    }
  }, [data]);

  if (isLoading) return <Loader />;
  if (isError) {
    console.error("Error fetching invoice details:", error);
    return <div>Error loading invoice details.</div>;
  }

  const paymentMethods = [
    {
      label: "Pay with Card Online",
      icon: <IoCalendarNumber />,
      value: "card",
      bgColor: "bg-[#F3FBFF]",
    },
    {
      label: "Pay with Bank Branch",
      icon: <IoCalendarNumber />,
      value: "bank",
      bgColor: "bg-[#FFF4EE]",
    },
  ];

  const handleMethodSelect = (method: string | null) => {
    setSelectedMethod(method);
  };

  const handlePayment = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (selectedMethod === "card" && formData) {
      const form = e.currentTarget;
      form.submit();
    } else if (selectedMethod === "bank") {
      navigate(`/ext-bank-branch/${data?.txnRef}`);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <div className="bg-white my-8 rounded-xl border-[1px] border-border p-5 max-w-[80vw] py-[20px]">
        <h2 className="text-2xl font-semibold mb-6">Complete Payment</h2>

        {formData && (
          <form
            method="POST"
            action="https://newwebpay.interswitchng.com/collections/w/pay"
            onSubmit={handlePayment}
          >
            <input type="hidden" name="amount" value={formData.amount} />
            <input type="hidden" name="currency" value={formData.currency} />
            <input type="hidden" name="txn_ref" value={formData.txn_ref} />
            <input
              type="hidden"
              name="merchant_code"
              value={formData.merchant_code}
            />
            <input
              type="hidden"
              name="pay_item_id"
              value={formData.pay_item_id}
            />
            <input
              type="hidden"
              name="site_redirect_url"
              value={formData.site_redirect_url}
            />
            <input type="hidden" name="display_mode" value="PAGE" />

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-8">
              <FormField label="Name" value={formData.name} />
              <FormField label="Reg Number" value={formData.regNumber} />
              <FormField label="Department" value={formData.department} />
              <FormField label="Level" value={formData.level} />
              <FormField label="Faculty" value={formData?.faculty} />
              <FormField
                label="Transaction Reference"
                value={formData.txn_ref}
              />
              <FormField label="Amount" value={`₦${formData.amount / 100}`} />
            </div>

            <h2 className="text-2xl font-semibold mb-6">Payment method</h2>
            <div className="flex gap-8 mb-8">
              {paymentMethods.map((method, index) => (
                <div
                  key={index}
                  className={`flex gap-2 items-center p-6 cursor-pointer transition duration-300 
                    ${method.bgColor} ${
                    selectedMethod === method.value
                      ? "border-2 border-red-500"
                      : ""
                  }`}
                  onClick={() => handleMethodSelect(method.value)}
                >
                  {method.icon}
                  <div>{method.label}</div>
                </div>
              ))}
            </div>

            <Button
              type="submit"
              label={
                selectedMethod === "card" ? "Pay with Interswitch" : "Next"
              }
              className="w-full"
              disabled={!selectedMethod}
            />
          </form>
        )}
      </div>
    </div>
  );
};

export default ExtPaymentSummary;

export const FormField: React.FC<{
  label: string;
  value?: string | number;
}> = ({ label, value }) => (
  <div className="flex flex-col mb-4">
    <label className="text-sm font-medium text-gray-600 mb-1">{label}</label>
    <div className="border border-gray-400 rounded-md p-3 text-gray-600 bg-gray-50">
      {value}
    </div>
  </div>
);
