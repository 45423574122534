import { useEffect, useState } from "react";
import { BiLogInCircle } from "react-icons/bi";
import { Button } from "components/Form";
import { executeGetSchools } from "apis/schools";
import toast from "react-hot-toast";
import { useAppDispatch } from "hooks/redux";
import { populateSelectedSchool } from "features/schoolSlice";
import { RiArrowDropDownLine } from "react-icons/ri";
import useDisclosure from "hooks/useDisclosure";
import Skeleton from "components/Skeleton";

interface School {
  logo: string;
  name: string;
}

interface LoginProps {
  setActiveStep: (no: any) => void;
}

const SelectSchool: React.FC<LoginProps> = ({ setActiveStep }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<School | null>(null);
  const [schools, setSchools] = useState<School[] | []>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useAppDispatch();
  const {
    isOpen: isLoading,
    onOpen: openLoading,
    onClose: closeLoading,
  } = useDisclosure();

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: School) => {
    setSelectedOption(option);
    dispatch(populateSelectedSchool(option));
    setSearchTerm("");
    setIsOpen(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleGetSchools = async () => {
    try {
      openLoading();
      const result = await executeGetSchools();
      setSchools(result.data);
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      closeLoading();
    }
  };

  useEffect(() => {
    handleGetSchools();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredSchools = schools
    ? schools.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  return (
    <div className="min-h-screen flex flex-col justify-between bg-my">
      <div className="flex flex-1 justify-center items-center p-6 lg:p-0">
        <div className="flex w-full lg:w-3/4 max-w-5xl items-center gap-24">
          <div className="w-1/2 flex justify-center lg:justify-end p-6 lg:p-0">
            <img
              src="/images/kano.png"
              alt="logo"
              className="w-2/3 h-auto object-contain"
            />
          </div>

          <div className="w-1/2 bg-white rounded-md shadow-lg p-8">
            <h1 className="text-lg font-semibold text-gray-800 mb-4">
              Login
            </h1>
            <form>
              <div className="relative mb-6">
                <label htmlFor="school" className="block text-gray-600 mb-2">
                  Name of Institution
                </label>
                <div className="relative w-full">
                  <input
                    type="text"
                    onFocus={handleDropdownClick}
                    value={selectedOption?.name || searchTerm}
                    onChange={handleInputChange}
                    className="border border-gray-300 rounded-md p-2 bg-gray-200 w-full pr-10"
                    placeholder="Select an option"
                  />
                  <RiArrowDropDownLine
                    onClick={handleDropdownClick}
                    className="absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-500 text-xl cursor-pointer"
                  />
                </div>
                {isOpen &&
                  (isLoading ? (
                    <Skeleton />
                  ) : (
                    <div className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg">
                      <ul className="max-h-60 overflow-y-auto">
                        {filteredSchools.map((item) => (
                          <li
                            key={item.name}
                            onClick={() => handleOptionClick(item)}
                            className="cursor-pointer px-4 py-2 hover:bg-gray-100 flex items-center"
                          >
                            <img
                              src={item.logo}
                              alt={item.name}
                              className="w-6 h-6 mr-3"
                            />
                            <span>{item.name}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
              </div>
              <Button
                label="Proceed"
                Icon={BiLogInCircle}
                onClick={() => setActiveStep((prev: number) => prev + 1)}
                className="w-full bg-red-500 hover:bg-red-600 mt-6"
              />
            </form>
          </div>
        </div>
      </div>

      <footer className="text-center text-sm text-white p-4">
        <p>Copyright © 2024 Kano State Revenue Agency. All rights reserved.</p>
        <div className="flex justify-center items-center mt-2">
          <span className="mr-1">Powered by</span>
          <img
            src="/images/logo.png"
            alt="logo"
            className="w-14 h-8 object-contain"
          />
        </div>
      </footer>
    </div>
  );
};

export default SelectSchool;
