import Layout from "Layout";
import { useNavigate } from "react-router-dom";
import CustomTable from "components/Table/CustomTable";
import { useQuery } from "@tanstack/react-query";
import { actions, columns } from "../Student/helpers";
import StudentTableHeader from "components/TableHeaders/StudentTableHeader";
import { executeGetStudentsBySchool } from "apis/students";
import { useAppSelector } from "hooks/redux";
import { useState } from "react";
import CreateStudentForm from "./CreateStudentForm";

function StudentsTable() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const openNewItemModal = () => {
    setIsModalOpen(true);
  };

  const closeNewItemModal = () => {
    setIsModalOpen(false);
  };

  const school = useAppSelector((state) => state.school.selectedSchool!);

  const previewPayment = (id: number) => {
    navigate(`/patients/preview/${id}`);
  };

  const { data } = useQuery({
    queryKey: ["students", school.id],
    queryFn: executeGetStudentsBySchool,
  });

  return (
    <Layout>
      <StudentTableHeader openNewItemModal={openNewItemModal} />
      {isModalOpen && <CreateStudentForm onCloseForm={closeNewItemModal} />}
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="10"
        data-aos-offset="200"
        className="my-8 rounded-xl border-[1px] border-border"
      >
        <div className="w-full overflow-x-scroll bg-white p-5">
          <CustomTable
            data={data || []}
            columns={columns}
            actions={actions(previewPayment)}
          />
        </div>
      </div>
    </Layout>
  );
}

export default StudentsTable;
