import { useQuery } from "@tanstack/react-query";
import Layout from "Layout";
import { executeGetBankPayments } from "apis/schools";
import Loader from "components/Notifications/Loader";
import CustomTable from "components/Table/CustomTable";
import { useAppSelector } from "hooks/redux";
import { paymentItemColumns } from "screens/WebPay/helpers";

function BankChannel() {
  const school = useAppSelector((state) => state.school.selectedSchool!);
  const token = useAppSelector((state) => state.account.token.value);

  const { data: payments, isLoading } = useQuery({
    queryKey: ["bank-payments", school.id, token],
    queryFn: () =>
      executeGetBankPayments({
        queryKey: ["bank-payments", school.id],
        token,
      }),
  });

  return (
    <Layout>
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="10"
        data-aos-offset="200"
        className="bg-white my-8 rounded-xl border-[1px] border-border p-5"
      >
        <div className="mt-8 w-full overflow-x-scroll">
          {isLoading ? (
            <Loader />
          ) : (
            <CustomTable data={payments || []} columns={paymentItemColumns} />
          )}
        </div>
      </div>
    </Layout>
  );
}

export default BankChannel;
