import React from "react";
import { MenuSelect } from "../components/Form";
import { TbUser } from "react-icons/tb";
import { AiOutlinePoweroff } from "react-icons/ai";
// import { MdOutlineNotificationsNone } from "react-icons/md";
// import NotificationComp from "../components/NotificationComp";
import { useLocation, useNavigate } from "react-router-dom";
import { BiMenu } from "react-icons/bi";
import MenuDrawer from "../components/Drawer/MenuDrawer";
import { MenuDatas } from "components/Datas";
import { clearAccount } from "features/accountSlice";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import SchoolSearch from "screens/SchoolSearch";

interface HeaderProps {
  title?: string;
}
const Header = ({ title }: HeaderProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const role = useAppSelector((state) => state.account.role.roleName);
  const user = useAppSelector((state) => state.account.user);
  const schoolName = useAppSelector(
    (state) => state.school.selectedSchool?.name
  ); // assuming schoolName is in state
  const location = useLocation();
  const currentPath = location.pathname;
  const currentPage = MenuDatas.find((menu) => menu.path === currentPath);
  const dispatch = useAppDispatch();

  // toggle drawer
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const navigate = useNavigate();
  const DropDown1 = [
    {
      title: "Profile",
      icon: TbUser,
      onClick: () => {
        navigate("/settings");
      },
    },
    {
      title: "Logout",
      icon: AiOutlinePoweroff,
      onClick: () => {
        dispatch(clearAccount());
        navigate("/login");
      },
    },
  ];

  const getInitials = (
    firstName: string | undefined,
    lastName: string | undefined
  ) => {
    if (!firstName || !lastName) return "";
    return `${firstName.charAt(0).toUpperCase()}${lastName
      .charAt(0)
      .toUpperCase()}`;
  };

  return (
    <>
      {isOpen && <MenuDrawer isOpen={isOpen} toggleDrawer={toggleDrawer} />}

      <div className="xl:w-5/6 w-full 2xl:max-w-[1640px] bg-white grid md:grid-cols-2 grid-cols-12 items-center bg-opacity-95 fixed top-0 xs:px-8 px-2">
        <div className="md:col-span-1 sm:col-span-11 col-span-10 flex gap-4 items-center md:py-0 py-4">
          <button
            onClick={toggleDrawer}
            className="block xl:hidden border text-2xl bg-greyed w-16 md:w-12 h-12 rounded-md flex-colo text-textGray transitions hover:bg-border"
          >
            <BiMenu />
          </button>
          <h1 className="text-xl font-semibold">{currentPage?.title}</h1>
          <div className="md:w-96 w-full h-12 flex items-center">
            {/* Conditionally render the school search input or school name */}
            {role === "executive" ? (
              <SchoolSearch />
            ) : (
              <div className="flex items-center w-full h-full p-2 bg-gray-100 rounded-lg">
                <p className="text-base text-gray-600">{schoolName}</p>
              </div>
            )}
          </div>
        </div>
        <div className="md:col-span-1 sm:col-span-1 col-span-2 items-center justify-end pr-4 md:pr-0">
          <div className="float-right flex gap-4 items-center justify-center">
            {/* <NotificationComp>
              <div className="relative">
                <MdOutlineNotificationsNone className="text-2xl hover:text-subMain" />
                <span className="absolute -top-2.5 -right-2.5 font-semibold bg-subMain rounded-full px-1.5 py-0.5 text-xs text-white text-center">
                  5
                </span>
              </div>
            </NotificationComp> */}

            <div className="items-center md:flex hidden">
              <MenuSelect datas={DropDown1}>
                <div className="flex gap-4 items-center p-4 rounded-lg">
                  {user.image ? (
                    <img
                      src={user.image}
                      alt="user"
                      className="w-12 border border-border object-cover h-12 rounded-full"
                    />
                  ) : (
                    <div className="flex items-center justify-center w-12 h-12 border border-border rounded-full bg-subMain">
                      <span className="text-sm text-white font-medium bg-subMain">
                        {getInitials(user?.firstName, user?.lastName)}
                      </span>
                    </div>
                  )}
                  <p className="text-sm text-textGray font-medium">
                    {user?.firstName} {user?.lastName}
                  </p>
                </div>
              </MenuSelect>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
