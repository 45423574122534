import Layout from "Layout";
import { Link, useNavigate } from "react-router-dom";
import { BiPlus } from "react-icons/bi";
import TableHeader from "components/Table/TableHeader";
import CustomTable from "components/Table/CustomTable";
import paymentData, { actions, columns } from "screens/WebPay/helpers";

function History() {
  const navigate = useNavigate();

  const previewPayment = (id: number) => {
    navigate(`/patients/preview/${id}`);
  };

  return (
    <Layout>
      <TableHeader />
      <Link
        to="/patients/create"
        className="w-16 animate-bounce h-16 border border-border z-50 bg-subMain text-white rounded-full flex-colo fixed bottom-8 right-12 button-fb"
      >
        <BiPlus className="text-2xl" />
      </Link>

      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="10"
        data-aos-offset="200"
        className="bg-white my-8 rounded-xl border-[1px] border-border p-5"
      >
        <div className="mt-8 w-full overflow-x-scroll">
          <CustomTable
            data={paymentData}
            columns={columns}
            actions={actions(previewPayment)}
          />
        </div>
      </div>
    </Layout>
  );
}

export default History;
