import axios from "axios";
import {
  REGISTER_ENDPOINT,
  SET_PASSWORD_ENDPOINT,
  VERIFY_EMAIL_ENDPOINT,
  VERIFY_2FA,
  SETUP_2FA,
  RESEND_2FA_ENDPOINT,
  AUTH_ENDPOINTS,
} from "./index";

export const RegisterUser = async (
  data: FormData
): Promise<ResponseDataType> => {
  try {
    const response = await axios.post<ResponseDataType>(
      REGISTER_ENDPOINT,
      data
    );
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      return error.response.data;
    } else {
      return { message: error.message, status: "error" } as ResponseDataType;
    }
  }
};

export const verifyEmail = async (
  data: VerifyEmailData
): Promise<ResponseDataType> => {
  try {
    const response = await axios.post<ResponseDataType>(
      VERIFY_EMAIL_ENDPOINT,
      data
    );
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      return error.response.data;
    } else {
      return { message: error.message, status: "error" } as ResponseDataType;
    }
  }
};

export const setPassword = async (
  data: SetPasswordResponse
): Promise<ResponseDataType> => {
  try {
    const response = await axios.post<ResponseDataType>(
      SET_PASSWORD_ENDPOINT,
      data
    );
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      return error.response.data;
    } else {
      return { message: error.message, status: "error" } as ResponseDataType;
    }
  }
};

export const VERIFY2FA = async (
  data: Verify2FaData
): Promise<ResponseDataType> => {
  try {
    const options = {
      method: "POST",
      url: VERIFY_2FA,
      data: data,
    };

    const response = (await axios(options)) satisfies ResponseDataType;
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: error };
  }
};

export const executeLogin = async (
  data: LoginData
): Promise<ResponseDataType> => {
  try {
    const options = {
      method: "POST",
      url: AUTH_ENDPOINTS.LOGIN_ENDPOINT,
      data: data,
    };

    const response = (await axios(options)) satisfies ResponseDataType;
    return response.data;
  } catch (error: any) {
    return {
      message: error.response.data.message,
      isSuccess: false,
      status: error,
    };
  }
};

export const executeSetup2FA = async (
  token: string,
  mfa_type: string,
  employee_id: string
): Promise<ResponseDataType> => {
  try {
    const options = {
      method: "POST",
      url: SETUP_2FA,
      data: { token, mfa_type, employee_id },
    };

    const response = (await axios(options)) satisfies ResponseDataType;
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: error };
  }
};

export const executeResend2Fa = async (
  employeeId: string
): Promise<ResponseDataType> => {
  try {
    const options = {
      url: RESEND_2FA_ENDPOINT(employeeId),
    };
    const response = (await axios(options)) satisfies ResponseDataType;
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: error };
  }
};
