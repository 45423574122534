import { useEffect, useState } from "react";
import { Button } from "components/Form";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { verifyEmail } from "apis/auth";
import { useAppDispatch } from "hooks/redux";
import { populateAccount } from "features/accountSlice";

const EmailVerification = () => {
  const [isVerified, setIsVerified] = useState<boolean | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const dispatch = useAppDispatch();

  useEffect(() => {
    const verifyUserEmail = async () => {
      if (!email || !token) {
        toast.error("Invalid URL parameters.");
        return;
      }
      try {
        const response = await verifyEmail({ email: email!, token: token! });

        if (response.status === "success") {
          setIsVerified(true);
          dispatch(
            populateAccount({
              token: response.data.reset_token,
              user: { email },
            })
          );

          console.log({ token: response.data.reset_token });
        } else {
          setIsVerified(false);
        }
      } catch (error) {
        toast.error("An error occurred. Please try again.");
        setIsVerified(false);
      } finally {
        setLoading(false);
      }
    };

    verifyUserEmail();
  }, [dispatch, email, token]);

  if (loading) {
    return (
      <div className="w-full h-screen flex items-center justify-center bg-gray-100">
        <div className="flex flex-col items-center">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          <p className="text-center text-green-700">Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-screen flex items-center justify-center bg-gray-100">
      {isVerified ? (
        <div className="w-96 p-8 rounded-lg shadow-lg bg-white text-center">
          <div className="mb-4">
            <img
              src="/images/checkmark.png"
              alt="Success"
              className="w-12 h-12 mx-auto"
            />
          </div>
          <h2 className="text-2xl font-semibold text-subMain mb-4">Welcome</h2>
          <p className="mb-4">Your account has been verified.</p>
          <Button
            label="Set your password"
            onClick={() => navigate("/set-password")}
            loading={false}
            className="mb-4 w-full"
          />
        </div>
      ) : (
        <div className="w-96 p-8 rounded-lg shadow-lg bg-white text-center">
          <div className="mb-4">
            <img
              src="/images/error.png"
              alt="Error"
              className="w-12 h-12 mx-auto"
            />
          </div>
          <h2 className="text-2xl font-semibold text-red-700 mb-4">
            Email verification link expired
          </h2>
          <p className="mb-4">
            Looks like the email verification link has expired. No worries, we
            can send the link again.
          </p>
          <Button
            label="Resend verification link"
            className={"w-full"}
            onClick={() => navigate("/resend-verification")} // Assuming you have a route to resend verification
            loading={false} // Ensure the button is clickable
          />
        </div>
      )}
    </div>
  );
};

export default EmailVerification;
