import { CiCirclePlus, CiUndo } from "react-icons/ci";

interface StudentTableHeaderProps {
  openNewItemModal: () => void;
}

const StudentTableHeader = ({ openNewItemModal }: StudentTableHeaderProps) => {
  const handleGetCSVTemplate = () => {
    const columns = [
      "first_name",
      "last_name",
      "email",
      "phone_number",
      "gender",
      "matric_number",
      "department",
      "year_of_enrollment",
      "current_level",
    ];

    const csvContent = columns.join(",");

    // Create a Blob with the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a download link and trigger the download
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "student_template.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className="flex justify-between">
      <div className="flex gap-4">
        <input
          type="search"
          className="border py-2 px-4 text-sm border-[#D5D5D5] rounded-3xl"
          placeholder="Search keywords"
        />
        <button
          className="flex font-semibold items-center gap-2 border rounded-3xl py-2 px-4 text-sm border-[#D5D5D5] text-[#202224]"
          onClick={() => openNewItemModal()}
        >
          <CiCirclePlus size={18} />
          New
        </button>
        <button className="flex items-center font-semibold gap-2 text-[#EA0234] rounded-3xl border py-2 px-4 text-sm border-[#D5D5D5]">
          <CiUndo size={18} />
          Reset
        </button>
      </div>

      <button
        className="px-4 bg-white border rounded-3xl text-sm text-[#049621]"
        onClick={handleGetCSVTemplate}
      >
        Get CSV Template
      </button>
    </div>
  );
};

export default StudentTableHeader;
