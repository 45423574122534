import axios from "axios";
import {
  BANK_PAYMENT_ENDPOINT,
  EXTERNAL_TRANSACTION_DETAILS_ENDPOINT,
  INVOICE_DETAILS_ENDPOINT,
  PAYMENT_ENDPOINT,
  SCHOOL_ENDPOINT,
  WEB_PAYMENT_ENDPOINT,
} from ".";

export const executeGetSchools = async (): Promise<ResponseDataType> => {
  try {
    const options = {
      url: SCHOOL_ENDPOINT,
    };
    const response = (await axios(options)) satisfies ResponseDataType;
    console.log({ response });
    return response.data.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: error };
  }
};

export const executeGetPayments = async ({
  token,
}: {
  queryKey: [string, number];
  token: string;
}) => {
  const response = await axios.get(PAYMENT_ENDPOINT, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.data.data;
};

export const executeGetBankPayments = async ({
  queryKey,
  token,
}: {
  queryKey: [string, number];
  token: string;
}) => {
  const [, id] = queryKey;
  console.log({ id });
  const response = await axios.get(BANK_PAYMENT_ENDPOINT, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.data.data;
};

export const executeGetWebPayments = async ({
  queryKey,
  token,
}: {
  queryKey: [string, number];
  token: string;
}) => {
  const [, id] = queryKey;
  console.log({ id });
  const response = await axios.get(WEB_PAYMENT_ENDPOINT, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.data.data;
};

export const executeGetInvoiceDetails = async ({
  queryKey,
  token,
}: {
  queryKey: [string, string];
  token: string;
}) => {
  const [, id] = queryKey;
  const response = await axios.get(INVOICE_DETAILS_ENDPOINT(id), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.data;
};

export const executeGetExtTransactionsDetails = async ({
  queryKey,
  token,
}: {
  queryKey: [string, string];
  token: string;
}) => {
  const [, id] = queryKey;
  const response = await axios.get(EXTERNAL_TRANSACTION_DETAILS_ENDPOINT(id), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.data;
};
