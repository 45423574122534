import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// Import Swiper styles
import "swiper/css";
import "aos";
import "aos/dist/aos.css";
import reportWebVitals from "./reportWebVitals";
import "react-tooltip/dist/react-tooltip.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-modern-drawer/dist/index.css";
import ReduxProvider from "store/Provider";

const rootElement = document.getElementById("root");
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <ReduxProvider>
      <App />
    </ReduxProvider>
  );
} else {
  console.error(
    "Root element not found. Make sure the element with id='root' exists in your HTML."
  );
}

reportWebVitals();
