import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "hooks/redux";
import { useEffect } from "react";

const ProtectedRoute = ({
  children,
  requiredModule,
}: {
  children: any;
  requiredModule: string | undefined;
}) => {
  const { isAuthenticated, dashboardModules } = useAppSelector(
    (state) => state.account
  );
  const location = useLocation();

  useEffect(() => {
    if (requiredModule && !dashboardModules.includes(requiredModule)) {
      console.warn(`Unauthorized access attempt to ${location.pathname}`);
    }
  }, [dashboardModules, requiredModule, location]);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (requiredModule && !dashboardModules.includes(requiredModule)) {
    return <Navigate to="/forbidden" />;
  }

  return children;
};

export default ProtectedRoute;
