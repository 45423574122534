import toast from "react-hot-toast";
import { FiEye } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";

export const columns = [
  {
    title: "Name",
    accessor: "name", // We will combine firstName and lastName in a render function
    render: (item: any) => `${item.firstName} ${item.lastName}`,
  },
  {
    title: "Matric No",
    accessor: "student.matricNumber", // Access the matricNumber from the student object
    render: (item: any) => item.student?.matricNumber || "N/A",
  },
  {
    title: "Email",
    accessor: "email", // Direct access to email
  },
  {
    title: "Faculty",
    accessor: "faculty", // Assuming faculty is not in the current response, leave blank
    render: () => "N/A", // Placeholder for future data or if not available
  },
  {
    title: "Level",
    accessor: "student.currentLevel", // Access the level from the student object
    render: (item: any) => item.student?.currentLevel || "N/A",
  },
  {
    title: "Department",
    accessor: "student.department.name", // Access department name from nested student.department object
    render: (item: any) => item.student?.department?.name || "N/A",
  },
  {
    title: "Phone",
    accessor: "phoneNumber", // Direct access to phoneNumber
  },
  {
    title: "Gender",
    accessor: "gender", // Direct access to gender
  },
].filter(Boolean);


export const actions = (previewPayment: (id: number) => void) => [
  {
    title: "Edit",
    icon: FiEye,
    onClick: (item: any) => {
      previewPayment(item.id);
    },
  },
  {
    title: "Delete",
    icon: RiDeleteBin6Line,
    onClick: () => toast.error("This feature is not available yet"),
  },
];

export const studentData = [
  {
    name: "Musa Mohammed",
    id: "ERG4883933",
    email: "musa.mo@example.com",
    faculty: "Social Sciences",
    level: "200",
    department: "Political Science",
    phone: "08012345678",
    gender: "Male",
  },
  {
  name: "Hassan Sani",
    id: "ERG4883932",
    email: "hassan@example.com",
    faculty: "Management Sciences",
    level: "400",
    department: "Banking and Finance",
    phone: "08012345678",
    gender: "Female",
  },
  {
    name: "Halima Baba",
    id: "ERG4883930",
    email: "halima@example.com",
    faculty: "Sciences",
    level: "100",
    department: "Medcine and Surgery",
    phone: "08012345678",
    gender: "Male",
  },
  {
    name: "Amos Dada",
    id: "ERG4883937",
    email: "Amos@example.com",
    faculty: "Arts",
    level: "400",
    department: "Theatre Arts",
    phone: "08012345678",
    gender: "Male",
  },
  {
    name: "Khalid Maina",
    id: "ERG4883938",
    email: "khalid@example.com",
    faculty: "Management Sciences",
    level: "400",
    department: "Accounting",
    phone: "08012345678",
    gender: "Male",
  },
  {
    name: "Aisha Sani",
    id: "ERG4883932",
    email: "ali@example.com",
    faculty: "Sciences",
    level: "400",
    department: "Laboratory Science",
    phone: "08012345678",
    gender: "Female",
  },
  {
    name: "Rachel Dauda",
    id: "ERG4883932",
    email: "rachal@example.com",
    faculty: "Applied Sciences",
    level: "500",
    department: "Physics",
    phone: "08012345678",
    gender: "Female",
  },
  {
    name: "Abba Shettima",
    id: "ERG4883932",
    email: "hassan@example.com",
    faculty: "Arts",
    level: "400",
    department: "Mass Communication",
    phone: "08012345678",
    gender: "Male",
  },
  {
    name: "Emeka Mazi",
    id: "ERG4883932",
    email: "emeka@example.com",
    faculty: "Socials Sciences",
    level: "400",
    department: "Public Administration",
    phone: "08012345678",
    gender: "male",
  },
  {
    name: "Promise Emmanuel",
    id: "ERG4883932",
    email: "promise@example.com",
    faculty: "Management Sciences",
    level: "400",
    department: "Banking and Finance",
    phone: "08012345678",
    gender: "Female",
  },
];

export default studentData;
